<template> 
    <div>
        <div class="product-review-summary">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <h2>Customer Reviews</h2>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-right">
                    <router-link :to="{ name : 'Login'}" class="orange-button"  v-if="!$store.getters.isAuthenticated">Write review</router-link>
                    <WriteReview :products='products' v-if="$store.getters.isAuthenticated"/>
                </div>
            </div>
            <div class="customer-review-summary">
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star nocolor"></i></span>
                <span class="summary-result">4 out of 5</span>
            </div>
            <div class="rating-result">{{ products.ratings_count }} ratings</div>
            <div class="rating-proggress" v-if="ratings && ratings.length > 0">
                <div class="rating-item" v-for="(rating) in ratings" :key="rating.id">
                    <div class="star">{{ rating.rating_value }} star</div>
                    <div class="proggressbar">
                        <span class="proggressbar-percent" :style="'width:' + rating.ratting_percentage + '%'"></span>
                    </div>
                    <div class="percent">{{ rating.ratting_percentage }}%</div>
                </div>
            </div>
        </div>
        
        <div class="customer-review-message px-2">            
            <div class="review-message-item" v-for="(rev) in produceReviews" :key="rev.id">
                <div class="customer-review-summary">
                    <div v-if="rev.rating_value > 0">
                        <span v-for="index in rev.rating_value" :key="index">
                            <i class="fa fa-star"></i>
                        </span>
                        
                    </div>

                    <span class="pl-2">by </span>
                    <span class="customer-name">{{ rev.customer.fullname }}</span>
                </div>
                <div class="customer-message">
                    <p>{{ rev.review}}</p>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import WriteReview from './WriteReview.vue';

export default {
    name: 'Instruction',
    props: ['products','ratings','produceReviews'],
    components:{
        WriteReview
    }
};
</script>

