<template> 
    <div class="disclaimer_section">
        <div class="row">
            <div class="col-md-12">
                <div class="inner_disclamier p-3">
                    <h5 class="disclaimer_title p-0 mb-2"><i class="far fa-sticky-note"></i> <span>Disclaimer</span></h5>
                    <p class="disclaimer_content">
                        The information provided herein is accurate, updated and complete as per the best practices of the Company. Please note that this information should not be treated as a replacement for physical
                        medical consultation or advice. We do not guarantee the accuracy and the completeness of the information so provided. The absence of any information and/or warning to any drug shall not be
                        considered and assumed as an implied assurance of the Company. We do not take any responsibility for the consequences arising out of the aforementioned information and strongly recommend you for a
                        physical consultation in case of any queries or doubts.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Disclaimer',
};
</script>

