<template> 
    <div>
         <div class="row">
            <div class="col-md-12">
                <div class="sectionHeading">
                    <h3>Best Selling</h3>
                    <div id="best-selling">
                        <button class="prev-btn" id="al" aria-label="left arrow"><i class="fa-solid fa-angle-left"></i></button>
                        <button class="next-btn" id="al" aria-label="right arrow"><i class="fa-solid fa-angle-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row best_selling_slider">
            <!-- class="col-6 col-sm-4 col-md-3 col-lg-2 p-0 m-0"  -->
            <div v-for="(item) in products" :key="item.id">
                <div class="productCard">
                    <div class="productImage">
                        <router-link :to="{ name: 'ProductDetails', params: {  code: item.code, slug: item.slug } }">
                            <img v-bind:src="item.image" v-bind:title="item.name" v-bind:alt="item.name" class="productCard-img-top w-100">
                        </router-link>
                    </div>
                    <div class="productCard-body">
                        <div class="productDetails">
                            <h5 class="productCard-title">{{ item.name.substring(0,50)+".." }}</h5>
                            <div class="ProductDiscount">
                                <span class="productDiscountPrice">${{ item.discount_amount }}</span>
                                <span class="discountPercent jss229">{{ item.discount }}{{ item.discount_type }} Off</span>
                            </div>
                            <p class="ProductPrice">${{ item.price }}</p>
                            
                        </div>
                        <a href="javascript:void(0)" @click="$store.dispatch('addToCart', {product_id:item.id, seller_id:item.seller_id})" class="btn btn-outline-primary"><i class="fa fa-shopping-basket"></i> Add to Cart</a>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from '@/utils/axiosConfig';

export default {
    name: 'BestSelling', 
    
    data(){
        return{
            error: '',
            products: [],
        }
    },
    mounted() {
        this.getProduct();
    },
    methods:{   
        getProduct() {
            axios.get(`best-selling-products`).then((response) => {
                if(response.data.success){
                    this.products = response.data.data;
                    this.slider()
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        },
        slider(){
            setTimeout(function() {
                    let sliderCount = 5;
                if (window.matchMedia('screen and (max-width: 480px)').matches) {
                    sliderCount = 2;
                }
                else if (window.matchMedia('screen and (max-width: 768px)').matches) {
                        sliderCount = 3;
                }
                else if (window.matchMedia('screen and (max-width: 1024px)').matches) {
                        sliderCount = 4;
                }
                $('.best_selling_slider').slick({
                    infinite: true,
                    speed: 300,
                    nextArrow: "#best-selling .next-btn",
                    prevArrow: "#best-selling .prev-btn",
                    slidesToShow: sliderCount,
                    arrows:true,
                    mobileFirst: true,
                    slidesToScroll: 1,
                    autoplay:true,                            
                }); 
            }, 0);     
        }
    },
};
</script>

