<template> 
    <div>
        <div class="row category_slider" id="categorySlider" style="display:none">
            <div v-for="(item) in categories" :key="item.id">               
                <div class="thumbnail">
                    <router-link :to="{ path: '/gallery/'+item.slug }">
                        <img v-bind:src="item.image" v-bind:title="' the image represention for ' + item.name" v-bind:alt="' the image represention for ' + item.name" class="d-block w-100">
                        <h5 class="align-center">{{ item.name }}</h5>
                    </router-link>
                </div>  
            </div>          
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import axios from '@/utils/axiosConfig';

export default {
    name: 'Categories', 
    
    data(){
        return{
            error: '',
            categories: [],
            loaded: false,
        }
    },
    mounted() {
        this.getCategory();
    },
    methods:{   
        getCategory() {
            axios.get(`categories`).then((response) => {
                if(response.data.success){
                    this.loaded = true;
                    this.categories = response.data.data;
                    if(this.loaded){
                        document.getElementById("categorySlider").style.display = 'block'
                        this.slider()
                    }
                }
                else{
                    this.loaded = false;
                    this.error = 'URL Data Not Found';
                }            
            });
        },
        slider(){
            setTimeout(function() {
                let sliderCount = 5;
                if (window.matchMedia('screen and (max-width: 480px)').matches) {
                    sliderCount = 2;
                }
                else if (window.matchMedia('screen and (max-width: 768px)').matches) {
                        sliderCount = 3;
                }
                else if (window.matchMedia('screen and (max-width: 1024px)').matches) {
                        sliderCount = 4;
                }
                $('.category_slider').slick({
                    infinite: true,
                    speed: 300,
                    slidesToShow: sliderCount,
                    arrows:false,
                    mobileFirst: true,
                    slidesToScroll: 1,
                    autoplay:true,                            
                }); 
            }, 0);     
        }
    },
};
</script>

