<template> 
    <div>
        <!-- <div class="row">
            <div class="merchant-bg-images d-flex">
                <div class="col-sm-12 text-center justify-content-center align-self-center d-flex text-shadow-headline">
                    {{ merchant.name }}
                </div>
            </div>
        </div> -->
        <div class="container">
            <div class="row" v-if="product.length > 0">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-0 m-0" v-for="(item) in product" :key="item.id">
                    <div class="productCard">
                        <div class="productImage">  
                            <router-link :to="{ name: 'ProductDetails', params: {  code: item.code, slug: item.slug } }">
                                <img v-bind:src="item.image" v-bind:title="item.name" v-bind:alt="item.name" class="productCard-img-top w-100">
                            </router-link>
                        </div>
                        <div class="productCard-body">
                            <div class="productDetails">
                                <!-- <h5 class="productCard-title">{{ item.name }}</h5> -->
                                <h5 class="productCard-title">{{ item.name.substring(0,50)+".." }}</h5>
                                <div class="ProductDiscount">
                                    <span class="productDiscountPrice">${{ item.discount_amount }}</span>
                                    <span class="discountPercent jss229">{{ item.discount }}{{ item.discount_type }} Off</span>
                                </div>
                                <p class="ProductPrice">${{ item.price }}</p>
                                <div class="rating">
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star-half-stroke"></i>
                                </div>
                            </div>
                            <a href="javascript:void(0)" @click="$store.dispatch('addToCart', {product_id:item.id, seller_id:item.seller_id})" class="btn btn-outline-primary d-grid">ADD TO CART</a>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axiosConfig';

export default {
    name: 'GalleryProducts',     
    props: ['product'],
    /*data(){
        return{
            error: '',
            products: [],
            merchant: [],
        }
    },
     mounted() {
        this.getProduct(this.$route.params.id);
    },
    methods:{   
        getProduct(id) {
            axios.get('products?seller_id=' + id).then((response) => {
                if(response.data.success){
                    this.products = response.data.data.data;
                    this.merchant = response.data.additional;
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }
    }, */
};
</script>

