<template> 
    <div class="container-fluid" id="grad1">
        <div class="row justify-content-center mt-0">
            <div class="col-11 col-sm-10 col-md-10 col-lg-10 text-center p-0 mt-3 mb-2">
                <div class="card px-0 pt-4 pb-0 mt-3 mb-3" style="border: 1px solid #eaeaea;">
                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <div id="msform" class="container">   
                                <ul id="progressbar">
                                    <li  class="active" id="account" style="width: 25%;"><strong>Sign In</strong></li>                                        
                                    <li id="personal" style="width: 25%;"><strong>Shipping Details</strong></li>
                                    <li id="shipping" style="width: 25%;"><strong>Shipment Cost</strong></li>
                                    <li id="payment"  style="width: 25%;"><strong>Payment</strong></li>
                                </ul>
                                <fieldset>
                                    <Login/>
                                </fieldset>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Login from "@/components/checkout/Login";

export default {
    name: 'CheckoutLogin', 
    components:{
        Login,
    },
    mounted() {
        if(this.$store.getters.isAuthenticated){
            this.$router.push({ path: '/shipping' })
        }
        else if(localStorage.getItem('isGuest')){
            this.$router.push({ path: '/shipping-cost' })
        }
    }
};
</script>   