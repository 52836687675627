<template>
    <div class="page-wrapper" id="pageWrapper">
        <div class="page-body-wrapper">          
            <div class="bg-images">
                <div class="container" style="padding-top: 50px;">
                    <Categories/> 
                </div>
            </div>
            <div class="container">                    
                <flash-sale/>
                <recent-arrival/>
                <marketplace/>
                <best-selling/>
                <free-shipping/>
                <fastival/>
                <random-products/>
            </div>                
        </div>
        <subscription/>
    </div>
</template>

<script>
import RecentArrival from '@/components/homepage/RecentArrival.vue'
import FlashSale from '@/components/homepage/FlashSale.vue'
import BestSelling from '@/components/homepage/BestSelling.vue'
import Categories from '@/components/homepage/Categories.vue'
import Fastival from '@/components/homepage/Fastival.vue'
import FreeShipping from '@/components/homepage/FreeShipping.vue'
import RandomProducts from '@/components/homepage/RandomProducts.vue'
import Marketplace from '@/components/homepage/Marketplace.vue'
import Subscription from '@/components/homepage/Subscription.vue'
// import {computed, reactive } from 'vue'
import { useHead } from '@unhead/vue'

export default {
   setup() {
        /* const siteData = reactive({
            title: `My website`,
            description: `My beautiful website`,
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                {
                    name: `description`,
                    content: computed(() => siteData.description),
                },
            ],        
        }) */
        useHead({
            title: "Ethnic Meal",
            meta: [
                {
                    name: `description`,
                    content: "EthnicMeal.com is a vibrant marketplace for authentic ethnic food products and accessories, featuring an array of exotic spices, herbs, rice, noodles, beans, legumes, and unique items you can't find anywhere else. Create your free account today to start selling organic ethnic food products. Explore our ultimate online platform to discover global flavors and traditional recipes that will elevate your cooking. Shop now for these foundational ingredients and other culinary delights that bring the world's cuisines right to your kitchen.",
                },
            ],        
        })
    },
    name: 'Home', 
    components: {
        RecentArrival,
        FlashSale,
        BestSelling,
        FreeShipping,
        Categories,
        Fastival,
        RandomProducts,
        Marketplace,
        Subscription
    }
};
</script>