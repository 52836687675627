<template>
    <div class="page-wrapper" id="pageWrapper">
    <div class="page-body-wrapper">          
            <div class="page-body">
                <fastival-product/>
            </div>
        </div>
    </div>
</template>

<script>
import FastivalProduct from '@/components/homepage/FastivalProduct.vue'

export default {
    name: 'Gallery', 
    components: {
        FastivalProduct
    }
};
</script>