<template> 
    <section class="instruction">
        <div class="card shadow-sm">
            <div class="card-body p-2">
                <div class="row">
                    <div class="col-md-5">
                        <div class="row">       
                            <div class="col-4 col-sm-4 col-md-3 col-lg-3">
                                <img class="w-100 corner-radius" v-bind:src="merchantData.logo" v-bind:title="businessData.name" v-bind:alt="businessData.name" />
                            </div>
                            <div class="col-8 col-sm-12 col-md-9 col-lg-9">
                                <h1>{{ businessData.name }}</h1>                            
                                <div>{{ businessData.slogan }}</div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-md-7">
                        <div class="pt-2 pb-2 text-sm-center text-lg-left">{{ businessData.name }} is a <strong>{{ merchantData.type }}</strong> Seller</div>
                        <div class="row">                            
                            <div class="col-sm-3">                                
                                <i class="fa-solid fa-truck-fast"></i>
                                <h5>Smooth shipping</h5>
                                <small>Has a history of shipping on time with tracking.</small>
                            </div>
                            <div class="col-sm-3">
                                <i class="fa-solid fa-envelope"></i>
                                <h5>Speedy replies</h5>
                                <small>Has a history of replying to messages quickly.</small>
                            </div>
                            <div class="col-sm-3">
                                <i class="fa-solid fa-star-half-stroke"></i>
                                <h5>Rave reviews</h5>
                                <small>Average review rating is 4.8 or higher.</small>
                            </div>
                            <div class="col-sm-3">
                                <div><img class="w-50 circle" v-bind:src="merchantData.logo" v-bind:title="businessData.name" v-bind:alt="businessData.name" /></div>
                                <small>{{ merchantData.name }}</small>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="container tabList">
            <ul>
                <li class="active" @click="scrollToContent('sellerItems')">Items</li>
                <li @click="scrollToContent('sellerReview')">Review</li>
                <li @click="scrollToContent('aboutSeller')">About</li>
                <li @click="scrollToContent('aboutSeller')">Shop Policies</li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Instruction',
    props: ['merchantData','businessData'],
    methods:{  
        scrollToContent(divId){
            // document.getElementById(divId).scrollIntoView();
            document.getElementById(divId).scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }
};
</script>

