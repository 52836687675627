<template> 
    <div id="user">
        <div class="container section_padding" v-if="$store.getters.isAuthenticated">
            <div class="row no-gutters">
                <div class="col-md-3 menu">
                    <Menu/>
                </div>
                <div class="col-md-9 data-table myorders">
                    <div class="headline">
                        <h3>My Account</h3>
                    </div>
                    <div class="col-sm-12">
                        <table class="table table-bordered table-striped">
                            <tr>
                                <td>Name</td>
                                <td>{{ user.fullname }}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{{ user.email }}</td>
                            </tr>
                            <tr>
                                <td>Contact</td>
                                <td>{{ user.contact }}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{{ user.address }}</td>
                            </tr>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>        
        <div class="container section_padding" v-if="!$store.getters.isAuthenticated">
            <div>Not logged in</div>
        </div>
    </div>
</template>

<script>
import Menu from './Menu.vue'
export default {
    name: 'Profile',  
    data() {
        return {
            user : ''
        }
    },
    components: {
        Menu
    },
    mounted() {
        if(this.$store.getters.isAuthenticated){
            this.user = this.$store.getters.StateUser
        }
        else{
            this.user = null;
             this.$router.push({ path: '/login' })
        }
    }
};
</script>

