<template>
    <div>
        <div class="col-sm-6 offset-lg-3" id="userMode">
            <form @submit.prevent="handleLogin" id="msform">                
                <div class="form-title"><h2>Login</h2></div>
                <ul v-if="showError">
                    <li v-for="(er) in errors" :key="er.id" class="errors text-center">
                        {{ er }}
                    </li>
                </ul>
                <div class="text-success">{{ ok }}</div>                    
                <div class="form-group row">
                    <div v-if="status" class="text-success text-center mb-2">{{ message }}</div>
                    <div v-else-if="!status" class="text-danger text-center mb-2">{{ message }}</div>
                        <div class="row form-card p-1">
                            <div class="col-sm-2 d-lg-block d-none d-sm-none mt-2">
                                <label for="customerEmail">Email</label>
                            </div>
                            <div class="col-sm-10">
                                <input type="email" v-model="form.username" id="customerEmail" placeholder="Enter Registered Email" autocomplete="off">
                            </div>
                        </div>
                        <div class="row form-card p-1">
                            <div class="col-sm-2 d-lg-block d-none d-sm-none mt-2">
                                <label for="customerPassword">Password</label>
                            </div>
                            <div class="col-sm-10">
                                <input type="password" v-model="form.password" id="customerPassword" placeholder="Enter Password" autocomplete="off">
                            </div>
                        </div>
                </div>
                <div class="form-group row">   
                    <div class="col-sm-12 mt-2">                               
                            <li style="display: block;">Don't have an account?
                                <router-link :to="{ name : 'SignUp'}" class="cursor anchor">Signup here</router-link></li>
                    </div>  
                    <div class="col-sm-12 mt-4">
                        <button type="submit" class="btn btn-primary">Sign In</button>
                        <a href="#" class="btn guestBtn" @click="getGuestOrLogin()">Continue as a guest</a>
                    </div>
                </div>
            </form>                             
        </div>
        <div id="guestMode" style="display:none">
            <Guest/>
        </div> 
    </div> 
</template>

<script>
import $ from 'jquery'
import { mapActions } from "vuex";
import Guest from "@/components/checkout/Guest";

export default {
    name: 'Login',
    components:{
        Guest,
    },
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
            status : '',
            message: '',            
            errors: [],
            showError: false
        }
    },
    methods: {
        getGuestOrLogin(){
            $("#guestMode").show()
            $("#userMode").hide()
        },
        ...mapActions(["Login"]),
        async handleLogin() {
            try {
                await this.Login(this.form);
                if(this.$store.getters.isAuthenticated){  
                    this.showError = false
                    this.$router.push({ path: '/shipping' })
                }
                else{
                    this.message = this.$store.getters.StateMessage
                    this.errors = this.$store.getters.StateErrors
                }
            } catch (error) {
                console.log(error)
                this.showError = true
            }
        }
    }
}
</script>