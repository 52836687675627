<template> 
    <div>
        <div class="row xzoom-container">
            <div class="col-sm-2">
                    <div class="proDetailsThumb">
                        <ul>
                            <li class="cursorPointer">
                                <img :id="'gallery'+products.code" v-bind:src="products.thumbImage" v-bind:title="products.name" 
                                v-bind:alt="products.name" :xpreview=products.largeImage :medium=products.image @click="changeImage(products.code)">
                            </li>
                            <li class="cursorPointer" v-for="(item) in products.product_galleries" :key="item.id">
                                <img :id="'gallery'+item.id" v-bind:src="item.thumbImage" v-bind:title="products.name" 
                                v-bind:alt="products.name" :xpreview=item.image :medium=item.mediumImage @click="changeImage(item.id)">                                                                                
                            </li>
                        </ul>
                    </div>
            </div>
            <div class="col-sm-10">
                <div class="proDetailsImageArea">
                    <img class="xzoom4" id="mainImgSrc" v-bind:src="products.largeImage"
                        :xoriginal=products.largeImage v-bind:title="products.name" v-bind:alt="products.name" />
                </div>
            </div>     
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import '../../assets/zoom/assets/css/xzoom.css'
import '../../assets/zoom/assets/js/xzoom.min.js'

export default {
    name: 'Images',
    props: ['products'],
    mounted() {
        if(this.products){
            this.zooms();
        }
    },
    methods:{  
        changeImage(id){
            var medSrc = $('#gallery'+id).attr('medium')
            var largeSrc = $('#gallery'+id).attr('xpreview')
            $('#mainImgSrc').attr('src', medSrc)
            $('#mainImgSrc').attr('xoriginal', largeSrc)
        },
        zooms(){
            $(document).ready(function() {
                $('.xzoom4').xzoom({zoomWidth: 600, tint: '#006699', Xoffset: 15});
                var isTouchSupported = 'ontouchstart' in window;
                if (isTouchSupported) {
                    $('.xzoom4').each(function(){
                        var xzoom = $(this).data('xzoom');
                        xzoom.eventunbind();
                    });

                    $('.xzoom4').each(function() {
                        var xzoom = $(this).data('xzoom');
                        $(this).hammer().on("tap", function(event) {
                            event.pageX = event.gesture.center.pageX;
                            event.pageY = event.gesture.center.pageY;
                            var s = 1, ls;

                            xzoom.eventmove = function(element) {
                                element.hammer().on('drag', function(event) {
                                    event.pageX = event.gesture.center.pageX;
                                    event.pageY = event.gesture.center.pageY;
                                    xzoom.movezoom(event);
                                    event.gesture.preventDefault();
                                });
                            }

                            var counter = 0;
                            xzoom.eventclick = function(element) {
                                element.hammer().on('tap', function() {
                                    counter++;
                                    if (counter == 1) setTimeout(openfancy,300);
                                    event.gesture.preventDefault();
                                });
                            }

                            function openfancy() {
                                if (counter == 2) {
                                    xzoom.closezoom();
                                    $.fancybox.open(xzoom.gallery().cgallery);
                                } else {
                                    xzoom.closezoom();
                                }
                                counter = 0;
                            }
                            xzoom.openzoom(event);
                        });
                    });
                }
            });
        }
    },
};
</script>

