<template>
    <div class="page-wrapper" id="pageWrapper">
    <div class="page-body-wrapper">          
            <div class="page-body">
                <MobileCategory/>
                <div class="container">
                    <gallery-products/>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import GalleryProducts from '@/components/homepage/GalleryProducts.vue'
import { useHead } from '@unhead/vue'
export default {
    setup(){
        useHead({
            title: "Gallery Page",
            meta: [
                {
                    name: `description`,
                    content: "EthnicMeal.com is a vibrant marketplace for authentic ethnic food products and accessories, featuring an array of exotic spices, herbs, rice, noodles, beans, legumes, and unique items you can't find anywhere else. Create your free account today to start selling organic ethnic food products. Explore our ultimate online platform to discover global flavors and traditional recipes that will elevate your cooking. Shop now for these foundational ingredients and other culinary delights that bring the world's cuisines right to your kitchen.",
                },
            ],        
        })
    },
    name: 'Gallery', 
    components: {
        GalleryProducts,
    }
};
</script>