<template> 
    <div class="container-fluid" id="grad1">
        <div class="row justify-content-center mt-0">
            <div class="col-11 col-sm-10 col-md-10 col-lg-10 text-center p-0 mt-3 mb-2">
                <div class="card px-0 pt-4 pb-0 mt-3 mb-3" style="border: 1px solid #eaeaea;">
                    <ul>
                        <li v-for="(er) in errors" :key="er.id" class="errors">
                            {{ er }}
                        </li>
                    </ul>
                <div class="text-success">{{ ok }}</div>
                <div class="text-danger">{{ error }}</div>                        
                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <div id="msform" class="container">   
                                <ul id="progressbar">
                                    <li  class="active" id="account" style="width: 25%;"><strong>Sign In</strong></li>                                        
                                    <li id="personal" v-bind:class="$store.getters.isAuthenticated ? 'active' : ''" style="width: 25%;"><strong>Shipping Details</strong></li>
                                    <li id="shipping" v-bind:class="$store.getters.isAuthenticated && shipping_id ? 'active' : ''" style="width: 25%;"><strong>Shipment Cost</strong></li>
                                    <li id="payment"  v-bind:class="$store.getters.isAuthenticated && shipping_id && merchants.length > 0 ? 'active' : ''" style="width: 25%;"><strong>Payment</strong></li>
                                </ul>                     
                                <fieldset>
                                    <span v-if="loading" class="fa-3x fa fa-spinner fa-spin"></span>
                                        <form @submit.prevent="processPayment">
                                        <div class="col-sm-9 offset-2 card-element">
                                            <div class="row">
                                                <div class="col-sm-8 p-0 m-0 card-area">
                                                    <div id="card-error-msg" style="color:red"></div>
                                                    <!-- <input type="text" v-model="card_name" placeholder="Card Name"> -->
                                                    <div id="card-element"></div>
                                                    <button type="submit" class="btn btn-success mt-4" id="paymentBtn">Place Order</button>
                                                </div>
                                                <div class="col-sm-4">
                                                    <PaymentSummery 
                                                        :itemTotal="itemTotal" 
                                                        :shippingCharge="shippingCharge" 
                                                        :beforeTax="beforeTax"
                                                        :couponPrice="couponPrice"
                                                        :grandTotal="grandTotal"
                                                        :totalTax="totalTax"/>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </form>

                                    <form @submit.prevent="orderPlace">                                            
                                        <input type="submit" style="display:none" class="action-button btn btn-success" value="Place Order" id="orderForm"/>
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.card-element{
    background-color: #F2F2F2;
    border-radius: 8px;
    box-shadow: #ccc 2px 0px 5px 2px;
    width: auto;
    margin: 30px auto;
}
.card-area{
    background-color: #fff;
    border-radius: 5px;
    padding: 15px;
}
.StripeElement {
  display: block;
  margin: 10px 5px;
  background-color: #fff;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  padding: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: 0;
}
.InputElement {
  font-size: 20px !important;
  color: #023d2e !important;
  font-weight: bold !important;
}
.StripeElement--focus {
    border-bottom: 2px solid #01896a;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}  
</style>
<script>
import $ from 'jquery'
import '../../assets/css/forms.css'
import { loadStripe } from '@stripe/stripe-js';
import axios from '@/utils/axiosConfig';
import PaymentSummery from '@/components/checkout/PaymentSummery.vue'

export default {
    name: 'Checkout', 
    components:{
        PaymentSummery
    },
    data(){
        return {
            loading: false,
            username: "",
            password: "",
            status : '',
            shipping_id : '',
            ok:'',          
            errors: [],
            merchants: [],
            rates: [],
            error: '',
            merchantWiseShipping: [],
            shippingCharge: 0,
            itemTotal: 0,
            beforeTax: 0,
            totalTax: 0,
            couponPrice: 0,
            grandTotal: 0,
            payment_method: '',
            stripe: null,
            elements: null,
            card: null,
            amount: 0,
            itemCountTotal: 0,
        }
    },    
    mounted() {           
        this.setupStripe()
        this.calculation()
    },
    methods: {  
        calculation: function () {
            this.itemTotal = parseFloat(localStorage.getItem('itemTotal'))
            this.beforeTax = parseFloat(localStorage.getItem('beforeTax'))
            this.totalTax = parseFloat(localStorage.getItem('totalTax'))
            this.shippingCharge = parseFloat(localStorage.getItem('shippingCharge'))
            this.grandTotal = parseFloat(localStorage.getItem('grandTotal'))
        },
        async setupStripe() {
            const ELEMENT_TYPE = "card";
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
            this.elements = this.stripe.elements();
            this.card = this.elements.create(ELEMENT_TYPE, {
                style: {
                    base: {
                        iconColor: '#666EE8',
                        color: '#31325F',
                        lineHeight: '30px',
                        fontWeight: 300,
                        fontFamily: 'Helvetica Neue',
                        fontSize: '18px',
                        fontSmoothing: 'antialiased',
                        ':-webkit-autofill': {
                            color: '#31325F',
                        },
                        '::placeholder': {
                            color: '#111',
                        },
                        },
                        invalid: {
                            iconColor: '#333',
                            color: '#FF0000',
                        }
                },
                hidePostalCode: true
            });
            this.card.mount('#card-element');
        },
        async processPayment() {
            this.loading = true
            const clientSecret = await this.createPaymentIntent();
            const { paymentIntent, error } = await this.stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: this.card,
                },
            });

            if (error) {
                $("#card-error-msg").html(error.message)
                this.loading = false
                $("#paymentBtn").attr('disabled', false);
                // $("#previousBtn").attr('disabled', false);
            } else if (paymentIntent.status === 'succeeded') {
                document.getElementById("orderForm").click();
            }
        },
        async createPaymentIntent() {
            $("#paymentBtn").attr('disabled', true);
            // $("#previousBtn").attr('disabled', true);
            let response = await fetch(process.env.VUE_APP_API_URL + 'create-payment-intent', {
                method: 'POST',
                body: JSON.stringify({ amount: parseFloat(localStorage.getItem('grandTotal')) }),
            });
            let { client_secret } = await response.json();
            return client_secret;
        },
        async orderPlace() {
            this.loading = true
            const requestData = {
                item_total: localStorage.getItem('itemTotal'),
                before_tax: localStorage.getItem('beforeTax'),
                totalTax: localStorage.getItem('totalTax'),
                shipping_charge: localStorage.getItem('shippingCharge'),
                total_price: localStorage.getItem('grandTotal'),
                shipping_id: localStorage.getItem('shipping_id'),
                guest_id: localStorage.getItem('guest_id'),
                merchant_shipping: localStorage.getItem('merchant_shipping'),
                payment_method: "Stripe",
                userType: localStorage.getItem('isGuest') ? "guest" : 'user'
            }

            try {
                let response = await axios.post(`place-order`, requestData);
                this.status = response.data.success
                this.ok = response.data.message
                this.errors = !response.data.success ? response.data.error : ''
                
                if (response.data.success) {
                    this.loading = false
                    localStorage.removeItem('itemTotal')
                    localStorage.removeItem('beforeTax')
                    localStorage.removeItem('totalTax')
                    localStorage.removeItem('cartItemTotal')
                    // localStorage.removeItem('vuex')
                    localStorage.removeItem('shippingCharge')
                    localStorage.removeItem('grandTotal')
                    localStorage.removeItem('merchant_shipping')
                    localStorage.removeItem('shipping_id')
                    localStorage.removeItem('shippingApiData')
                    this.$store.commit('updateMiniCart',[])
                    this.$store.commit('getSubTotal',0)
                    // localStorage.clear();
                    // window.location.reload()
                    this.$router.push({ path: '/thankyou' })
                }
            }
            catch (e) {
                $("#paymentBtn").attr('disabled', false);
                // $("#previousBtn").attr('disabled', false);
                this.loading = false
                this.error = e.message
            } 
        }
    }
};
</script>   