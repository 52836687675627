<template>
    <div class="page-wrapper" id="pageWrapper">
    <div class="page-body-wrapper">          
            <div class="page-body">
                <div class="container-fluid default-dash">
                    <div class="container-fluid default-dash">
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <div class="card mt-3">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6 m-0 p-0">
                                            <images :products='products'/>
                                        </div>
                                        <div class="col-md-6  m-0 p-0">
                                            <contents 
                                            :products='products'
                                            :merchants='merchants' 
                                            :totalsold='totalsold' 
                                            :error='error' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="single-product-view">
                        <div class="row justify-content-center">
                            <div class="col-xl-12 col-md-12">
                                <div class="card">
                                    <div class="card-body p-2">                                        
                                        <div class="product-details">
                                            <h4>Description</h4>
                                            <p v-html="products.details"></p>
                                        </div>

                                        <review :products='products' :ratings='ratings' :produceReviews='produceReviews'/>                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <merchant-items :moreproducts='merchant_product'/>
                    <more-items :moreproducts='moreproducts'/>                    
                    <disclaimer/>   
                </div>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axiosConfig';
import Review from '@/components/product_details/Review.vue'
import Images from '@/components/product_details/Images.vue'
import Contents from '@/components/product_details/Contents.vue'
import MoreItems from '@/components/product_details/MoreItems.vue'
import MerchantItems from '@/components/product_details/MerchantItems.vue'
import Disclaimer from '@/components/product_details/Disclaimer.vue'
import {computed, reactive } from 'vue'
import { useHead } from '@unhead/vue'

export default {
    name: 'ProductDetails',
    components: {
        Review,
        Images,
        Contents,
        MoreItems,
        MerchantItems,
        Disclaimer,
    },
    data(){
        return{
            error: '',
            products: [],       
            merchants: [],       
            moreproducts: [],      
            merchant_product: [],      
            totalsold: 0, 
            ratings: [], 
            produceReviews: [], 
        }
    },
    mounted() {
        this.getProduct();
    },
    methods:{
        getMetaData(){
        useHead({
            title: this.products.name,
            meta: [
                { 
                    name: 'viewport', 
                    content: 'width=device-width, initial-scale=1' 
                },
                {
                    name: `description`,
                    content: this.products.meta_description,
                },
                {
                    name: `keywords`,
                    content: this.products.keywords,
                },
                {
                    property: `og:url`,
                    content: this.products.slug,
                },
                {
                    property: `og:type`,
                    content: 'Website',
                },
                {
                    property: `og:site_name`,
                    content: 'Ethnic Meal',
                },
                {
                    property: `og:description`,
                    content: this.products.meta_description,
                },
                {
                    property: `og:image`,
                    content: this.products.image,
                },
            ],        
        })
        },
        getProduct() {
            axios.get('product/' + this.$route.params.code + '/'  + this.$route.params.slug).then((response) => {
                if (response.data.success) {
                    this.products = response.data.data.product;
                    this.produceReviews = response.data.data.produceReviews;
                    this.ratings = response.data.data.ratings;
                    this.merchants = response.data.data.product.merchant;
                    this.moreproducts = response.data.data.family_products;
                    this.merchant_product = response.data.data.merchants_products;
                    this.totalsold = response.data.data.total_sold;   
                    this.getMetaData() 
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }            
    },
};
</script>