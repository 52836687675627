<template> 
    <div id="user">
        <div class="container section_padding" v-if="user">
            <div class="row no-gutters">
                <div class="col-md-3 menu">
                    <Menu/>
                </div>
                <div class="col-md-9 data-table myorders">
                    <div class="headline">
                        <h3>My Orders</h3>
                    </div>
                    <div class="list card">
                        <div class="singleOrder" v-for="order in orders" :key="order.id"> 
                            <OrderDetails :orderDetails="order.get_order_details"/>                          
                            <div class="row" data-toggle="modal" data-target="#orderModal" style="cursor:pointer">
                                <div class="col-sm-6">
                                    <div class="orderDetailList">
                                        <span>Order ID</span>
                                        <strong># {{ order.order_number }}</strong>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Date</span>
                                        <span>{{ order.order_date }}</span>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Shipping to</span>
                                        <span>{{ order.customer.full_name }}</span>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Shipping to</span>
                                        <span>{{ order.customer.contact }}</span>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Delivery address</span>
                                        <span class="text-right" style="max-width:250px">{{ order.shipping.address }}</span>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Status</span>
                                        <span class="orderStatus">{{ order.status }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-1"></div>
                                <div class="col-sm-5">
                                    <div class="orderDetailList">
                                        <span>Order Amount</span>
                                        <strong>${{ order.order_payments.total_amount - order.order_payments.shipping_charge }}</strong>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Shipping Charge</span>
                                        <strong>${{ order.order_payments.shipping_charge }}</strong>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Payable Amount</span>
                                        <strong>${{ order.order_payments.total_amount }}</strong>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Paid Amount</span>
                                        <strong>${{ order.order_payments.paid_amount }}</strong>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Due Amount</span>
                                        <strong>${{ order.order_payments.due_amount }}</strong>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Payment Method</span>
                                        <strong>{{ order.order_payments.payment_method }}</strong>
                                    </div>
                                    <div class="orderDetailList">
                                        <span>Payment Status</span>
                                        <span v-bind:class="order.order_payments.status == 'Paid' ? 'paid' : 'unpaid'">
                                            {{ order.order_payments.status }}</span>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container section_padding" v-if="!user">
            <h3 class="alert alert-danger" role="alert">Not logged in</h3>
        </div>
    </div>
</template>


<script>
import axios from '@/utils/axiosConfig';
import Menu from './Menu.vue'
import OrderDetails from './OrderDetails.vue'
export default {
    name: 'MyOrder',  
    data() {
        return {
            user : '',
            orders : []
        }
    },
    components: {
        Menu,
        OrderDetails
    },
    mounted() {
        if (localStorage.getItem('user') != null) {
            this.user = JSON.parse(localStorage.getItem('user'))
        }
        else {
            this.user = '';
        }
        this.getOrders()
    },
    methods:{   
        getOrders() {
            axios.get(`orders`).then((response) => {
                if(response.data.success){
                    this.orders = response.data.data.data;
                    // console.log(response.data.data)
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }
    },
};
</script>

