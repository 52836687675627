<template> 
    <div class="disclaimer_section more-items">
        <h2>You may also like</h2>
        <div class="row">
            <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-0 m-0" v-for="(moreItem) in moreproducts" :key="moreItem.id">
                <div class="productCard">
                    <div class="productImage">
                        <router-link :to="{ name: 'ProductDetails', params: {  code: moreItem.code, slug: moreItem.slug } }">
                            <img v-bind:src="moreItem.image" v-bind:title="moreItem.name" v-bind:alt="moreItem.name" class="productCard-img-top w-100">
                        </router-link>
                    </div>
                    <div class="productCard-body">
                        <div class="productDetails">
                            <h5 class="productCard-title">{{ moreItem.name.substring(0,50)+".." }}</h5>
                            <div class="ProductDiscount">
                                <span class="productDiscountPrice">${{ moreItem.discount_amount }}</span>
                                <span class="discountPercent jss229">{{ moreItem.discount }}{{ moreItem.discount_type }} Off</span>
                            </div>
                            <p class="ProductPrice">${{ moreItem.price }}</p>
                            
                        </div>
                        <a href="javascript:void(0)" @click="$store.dispatch('addToCart', {product_id:moreItem.id, seller_id:moreItem.seller_id})"  class="btn btn-outline-primary"><i class="fa fa-shopping-basket"></i> Add to Cart</a>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
export default {
    name: 'MoreItems',
    props: ['moreproducts'],   
};
</script>

