<template>
    <div class="page-wrapper" id="pageWrapper">
        <div class="page-body-wrapper">          
            <div class="page-body">
                <div class="container justify-content-center align-items-center">
                    <img v-bind:src="content.image" v-bind:title="content.title" v-bind:alt="content.title" class="w-100">
                    <h2 class="page-heading">{{ content.title }}</h2>
                    <p class="page-content" v-html="content.content"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axiosConfig';

export default {
    name: 'Content',  
    data(){
        return{
            error: '',
            content: []
        }
    },
    mounted() {
        this.getContent();
    },
    methods:{   
        getContent() {
            axios.get(`contents/` + this.$route.params.slug).then((response) => {
                if(response.data.success){
                    this.content = response.data.data;
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        },
    },
};
</script>