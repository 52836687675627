<template> 
    <div class="container mt-5">
        <div class="col-sm-10 offset-1">
        <div id="authentication">
            <div class="auth-form card">
                <form @submit.prevent="registration">                
                    <div class="form-title"><h2>Customer Registration</h2></div>
                    <ul>
                        <li v-for="(er) in errors" :key="er.id" class="errors">
                            {{ er }}
                        </li>
                    </ul>
                    <div class="text-success">{{ ok }}</div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label for="customerFname" class="form-label">First Name</label>
                            <input type="text" class="form-control" v-model="form.first_name" placeholder="Enter your first name">
                        </div>
                        <div class="col-sm-6">
                            <label for="customerLname" class="form-label">Last Name</label>
                            <input type="text" class="form-control" v-model="form.last_name" placeholder="Enter your first name">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label for="customerEmail" class="form-label">Email</label>
                            <input type="email" class="form-control" v-model="form.email" placeholder="Enter registered email">
                        </div>
                        <div class="col-sm-6">
                            <label for="customerMobile" class="form-label">Mobile</label>
                            <input type="tel" class="form-control" v-model="form.contact" placeholder="Enter registered mobile no.">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label for="customerPassword" class="form-label">Password</label>
                            <input type="password" class="form-control" v-model="form.password" placeholder="Enter password">
                        </div>
                        <div class="col-sm-6">
                            <label for="customerCpassword" class="form-label">Confirm Password</label>
                            <input type="password" class="form-control" placeholder="Re-type password">
                        </div>
                    </div>
                    <div class="form-group row" style="margin-top: 40px;">   
                        <div class="col-sm-10">                               
                            <li style="display: block;">Already have an account? 
                                <router-link :to="{ name : 'Login'}" class="cursor anchor">Signin here</router-link></li>
                        </div>  
                        <div class="col-sm-2">
                            <button type="submit" class="btn btn-primary">Sign Up</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </div>
</div>
</template>

<script>
import axios from '@/utils/axiosConfig';

export default {
    name: 'SignUp',  
    data(){
        return{
            form:{
                first_name: "",
                last_name: "",
                email: "",
                contact: "",
                password: "",
            },
            errors: [],
            ok : ''
        }
    },
     mounted() {
    },
    methods:{  
        async registration(){            
            let response = await axios.post(`register`, this.form);
            if (response.data.success) {
                this.errors = '';
                this.form = '';
                this.ok = response.data.message
            }
            else {
                this.errors = response.data.error
            }
        },
    },
};
</script>

