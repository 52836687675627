<template>
    <div class="page-wrapper" id="pageWrapper">
        <div class="page-body-wrapper seller-wrapper">  
            <div class="white-bg">      
                <div class="container seller-padding">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                            <h3>{{ fastivals.name }}</h3>
                                <p class="mt-4 mb-5" v-html="fastivals.content ? fastivals.content?.substring(0,400) + '...' : '' "></p>
                                <router-link :to="{ path: '/fastival-product/'+fastivals.slug }" class="orange-button">        
                                View products</router-link>
                                <a href="javascript:void(0)" class="blue-text" onclick="window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);">View details <i class="fa fa-angle-down"></i> </a>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12">
                            <img v-bind:src="fastivals.image" v-bind:title="fastivals.name" v-bind:alt="fastivals.name" class="w-100">
                        </div>
                    </div>
                </div>
            </div>  
            <div class="orange-bg">
                <div class="container seller-wlecome seller-padding">
                    <img src="../assets/icon/seller_carrot_icon.png" class="icon-b mb-4">
                    <h2>Welcome to Ethnic Meal Fastival World</h2>
                </div>
            </div>
            <div class="white-bg">      
                <div class="container seller-padding">
                    <div class="col-sm-12 text-center mb-4">                        
                        <h4>Relevant Fastival</h4>
                    </div>
                    <div class="row">
                        <FastivalItem/>                       
                    </div>
                    <div class="col-sm-12 text-center mt-5">
                        <router-link :to="{ name: 'Fastival' }" class="orange-text">Get back to all fastival >></router-link>
                    </div>
                </div>
            </div>
            <div class="sky-bg">      
                <div class="container seller-padding">
                    <div class="row">
                            <p class="mt-4 mb-5" v-html="fastivals.content"></p>
                            <router-link :to="{path: '/fastival-product/'+fastivals.slug }" class="orange-button col-sm-2">View all products</router-link>
                    </div>
                </div>
            </div> 
               
            
                
        </div>
    </div>
</template>
<script>
import axios from '@/utils/axiosConfig';
import FastivalItem from '@/components/homepage/FastivalItem.vue'
export default {
    name: 'FastivalDetails', 
    components: {
        FastivalItem,
    },
    data(){
        return{
            error: '',
            fastivals: [],
        }
    },
    mounted() {
        this.getFastival();
    },
    methods:{   
        getFastival() {
            axios.get(`fastival-details/` + this.$route.params.slug).then((response) => {
                if(response.data.success){
                    this.fastivals = response.data.data;
                    // console.log(response.data.data)
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }
    },
};
</script>

