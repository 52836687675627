<template>
    <Header/>  
    <router-view :key="$route.fullPath"/>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/include/Header.vue'
import Footer from '@/components/include/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  created() {
  setTimeout(() => {
      localStorage.clear()
      console.log("Storage Cleared");
    }, 1000 * 60 * 60);
  }
}
</script>