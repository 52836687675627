<template> 
    <div>
        <div class="row">   

            <div class="col-lg-3 col-md-3 col-sm-12 text-center mt-5"  v-for="(item) in fastivals" :key="item.id">
                <router-link :to="{ path: '/fastival-product/'+item.slug }">
                    <img v-bind:src="item.image" v-bind:title="item.name" v-bind:alt="item.name" class="w-75 fastival-thumbnail">
                    <p class="text-center">{{ item.name }}</p>
                </router-link>
            </div>            
        </div>
    </div>
</template>
<script>
import axios from '@/utils/axiosConfig';

export default {
    name: 'FastivalItem', 
    
    data(){
        return{
            error: '',
            fastivals: [],
        }
    },
    mounted() {
        this.getFastival();
    },
    methods:{   
        getFastival() {
            axios.get(`fastivals?limit=4`).then((response) => {
                if(response.data.success){
                    this.fastivals = response.data.data;
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }
    },
};
</script>

