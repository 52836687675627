import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Gallery from '../views/Gallery.vue'
import ProductDetails from '../views/ProductDetails.vue'
import Cart from '../components/cart/Cart'
import SignUp from '../components/auth/SignUp'
import SignIn from '../components/auth/SignIn.vue'
import Profile from '../components/auth/Profile'
import MyOrder from '../components/auth/MyOrder'
import Content from '../views/Content.vue'
import SellerGallery from '../views/SellerGallery.vue'
import FastivalProduct from '../views/FastivalProduct.vue'
import Search from '../views/Search.vue'
import SellerSignup from '../views/merchant_auth/SellerSignup.vue'
import Default from '../views/Default.vue'
import Seller from '../views/Seller.vue'
import FlashSaleGallery from '../views/FlashSaleGallery.vue'
import Fastival from '../views/Fastival.vue'
import FastivalDetails from '../views/FastivalDetails.vue'
import store from '../store/modules/auth';
import ThankYou from '../components/checkout/ThankYou'
import Login from '../views/checkout/Login'
import Shipping from '../views/checkout/ShippingAddress'
import ShippingCost from '../views/checkout/ShippingCost'
import Payment from '../views/checkout/Payment'
import Checkout from '../views/checkout/Checkout'

const routes = [{
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/gallery/:slug',
    name: 'Gallery',
    component: Gallery,
  },
  {
    path: '/product-details/:code/:slug',
    name: 'ProductDetails',
    component: ProductDetails
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/marketplace',
    name: 'marketplace',     
    component: Seller
  },
  {
    path: '/default',
    name: 'Default',    
    component: Default
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: { 
      guest: true 
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: SignIn,
    meta: { 
      guest: true,
      requiresAuth: false
    },
  },
  {
    path: '/seller/signup',
    name: 'MerchantSignUp',    
    component: SellerSignup
  },
  
  {
    path: '/content/:slug',
    name: 'Content',
    component: Content
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/seller-store/:slug',
    name: 'SellerGallery',
    component: SellerGallery
  },
  {
    path: '/fastival-product/:slug',
    name: 'FastivalProduct',
    component: FastivalProduct
  },
  {
    path: '/flash-sale',
    name: 'FlashSaleGallery',
    component: FlashSaleGallery
  },
  {
    path: '/fastival',
    name: 'Fastival',
    component: Fastival
  },
  {
    path: '/fastival/:slug',
    name: 'FastivalDetails',
    component: FastivalDetails
  },
  ///////////////////// Checkout Area ////////////
  {
    path: '/checkout-login',
    name: 'CheckoutLogin',
    component: Login,
    meta: { 
      guest: true,
      requiresAuth: false
    },
  },  
  {
    path: '/shipping',
    name: 'Shipping',
    component: Shipping
  },  
  {
    path: '/shipping-cost',
    name: 'ShippingCost',
    component: ShippingCost
  }, 
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/thankyou',
    name: 'ThankYou',
    component: ThankYou
  },
  /////////////// Customer Profile ///////////
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true // Add meta field to indicate protected route
    }
  },
  {
    path: '/my-order',
    name: 'MyOrder',
    component: MyOrder,
    meta: {
      requiresAuth: true // Add meta field to indicate protected route
    }
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes
})

/* router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = store.getters.StateToken;
    if (token) {
      next();
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
}); */

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});


/* router.beforeRouteUpdate((to, from, next) => {
  next();
}) */


router.afterEach((to, from) => {
  //sendToAnalytics(to.fullPath)
  // console.log(to.fullPath)
})
export default router