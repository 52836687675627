<template> 
    <div>
         <div class="row">
            <div class="col-md-12">
                <h2 style="text-align: center; margin: 30px;">Products</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-sm-4 col-md-3 col-lg-2" v-for="(item) in products" :key="item.id" style="margin: 0; padding: 0;">
                <div class="productCard">
                    <div class="productImage">
                        
                        <router-link :to="{ name: 'ProductDetails', params: {  code: item.code, slug: item.slug } }">
                            <img v-bind:src="item.image" v-bind:title="item.name" v-bind:alt="item.name" class="productCard-img-top w-100">
                        </router-link>
                    </div>
                    <div class="productCard-body">
                        <div class="productDetails">
                            <h5 class="productCard-title">{{ item.name.substring(0,50)+".." }}</h5>
                            <div class="ProductDiscount">
                                <span class="productDiscountPrice">${{ item.discount_amount }}</span>
                                <span class="discountPercent jss229">{{ item.discount }}{{ item.discount_type }} Off</span>
                            </div>
                            <p class="ProductPrice">${{ item.price }}</p>
                            
                        </div>
                        <a href="javascript:void(0)" @click="$store.dispatch('addToCart', {product_id:item.id, seller_id:item.seller_id})"  class="btn btn-outline-primary"><i class="fa fa-shopping-basket"></i> Add to Cart</a>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from '@/utils/axiosConfig';

export default {
    name: 'RandomProducts', 
    
    data(){
        return{
            error: '',
            products: [],
        }
    },
    mounted() {
        this.getProduct();
    },
    methods:{   
        getProduct() {
            axios.get(`homepage-products`).then((response) => {
                if(response.data.success){
                    this.products = response.data.data;
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }
    },
};
</script>

