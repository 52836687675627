<template> 
    <div>
        <button class="orange-button" data-toggle="modal" data-target="#reviewMOdal">Write review</button>
        <div class="text-success">{{ ok }}</div>
        <div class="modal fade" id="reviewMOdal" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Write your review</h4>
                        <button type="button" class="close modal-close" data-dismiss="modal" id="al" aria-label="close">&times;</button>
                    </div>
                    <div class="modal-body" style="max-height:600px; overflow:auto; overflow-x:hidden">
                            <form @submit.prevent="registration">
                                <div class="listbox">
                                    <div class="col-md-12" style="margin-bottom:10px;">
                                        <h6>Your Opinion</h6>
                                        <textarea v-model="form.review" class="form-control"></textarea>
                                    </div>
                                    <div class="row" style="margin-bottom:40px;">
                                        <h6>Rate this</h6>
                                        <div class='movie_choice'>
                                            <div id="r1" class="rate_widget">
                                                <div class="ratings_stars" title="1"></div>
                                                <div class="ratings_stars" title="2"></div>
                                                <div class="ratings_stars" title="3"></div>
                                                <div class="ratings_stars" title="4"></div>
                                                <div class="ratings_stars" title="5"></div>
                                                <input type="hidden" id="ratingvalues" v-model="form.ratingVal" />
                                                <input type="hidden" id="productid" :value="products.id" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-8 offset-4">
                                        <input type="submit" value="Save Changes" class="btn btn-success">
                                    </div>
                                </div>
                            </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from '@/utils/axiosConfig';

export default {
    name: 'WriteReview',
    props: ['products'],
    data(){
        return{
            form:{
                review: "",
                ratingVal: 0
            },
            errors: [],
            ok : '',
            error: ''
        }
    },    
    mounted(){
        this.rating()
    },
    methods:{   
        rating(){
            $('.ratings_stars').click(function() {
                $(this).prevAll().addBack().addClass('ratings_over');
                var thisval = $(this).attr('title');
                $('#ratingvalues').val(thisval);
                $(this).nextAll().removeClass('ratings_over');
            });
        },
        modalClose() {
            $('.modal-close').trigger('click');
        },
        registration: function (e) {
            let postData = {
                review : this.form.review,
                rating_value : $('#ratingvalues').val(),
                product_id : $('#productid').val()
            }
            axios.post(`customer-review`, postData)
            .then((response) => {
                if(response.data.success){
                    this.ok = response.data.message;
                    this.form = '';
                    this.errors = '',
                    this.modalClose()
                    window.location.reload()
                }
                else{              
                    if (response.data.error !== undefined) {
                        this.errors = response.data.error;
                    }
                }             
            })            
            .catch(function(error){            
                console.log(error);
            });
            e.target.reset();
        },
    }
};
</script>

