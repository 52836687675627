<template> 
    <div class="footer-top">                
            Access Exclusive Offers, Exceptional Recipe Ideas, and Personalised Shopping Insights 
            from Ethnetic Feed <br/> - Your Culinary Companion.
            <div class="col-12 col-sm-3 offset-sm-4">
                <form action="#" class="input-area">
                    <input type="email" placeholder="Enter your email" name="subscribe-email">
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
</template>
<script>
export default {
    name: 'Subscription',
};
</script>

