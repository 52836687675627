<template>
    <div>
        <ul>
            <li>{{ $store.state.totalPrice }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Cart"
};
</script>