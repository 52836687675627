<template>
    <div>
            <table class="customTable" width="100%">
                <tr>
                    <td align="left">Items</td>
                    <td align="right">${{ itemTotal.toFixed(2) }}</td>
                </tr>
                <tr>
                    <td align="left">Shipping & Handling</td>
                    <td align="right">
                        <span id="total_shipping">${{ shippingCharge.toFixed(2) }}</span>
                    </td>
                </tr>
                <tr>
                    <td align="left">Total Before Tax</td>
                    <td align="right">${{ beforeTax.toFixed(2) }}</td>
                </tr>
                <tr>
                    <td align="left">Total Tax</td>
                    <td align="right">${{ totalTax.toFixed(2) }}
                        <input type="hidden" name="totalTaxVal" value="0" />
                    </td>
                </tr>
                <tr v-if="$store.getters.isAuthenticated">
                    <td colspan="2">
                        <table width="100%">
                            <tr id="cuponpricearea">
                                <td align="left">Coupon Discount</td>
                                <td align="right" id="cuponprice">${{ couponPrice }}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr style="border-top:1px solid #ccc">
                    <td height="38" align="left"><span class="grandTotal">Grand Total</span></td>
                    <td align="right"><span class="grandTotal">${{ grandTotal.toFixed(2) }}</span></td>
                </tr>               
            </table>
    </div>  
</template>

<script>
export default{
    name: "Addresses",
    props: ['itemTotal','shippingCharge','beforeTax','totalTax','couponPrice','grandTotal'] 
}
</script>