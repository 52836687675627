<template>
    <div class="card">
        <div class="order-summery-area">
            <h5>Order Summery</h5>
            <table class="customTable" width="100%">
                <tr>
                    <td align="left">Items</td>
                    <td align="right">${{ itemTotal.toFixed(2) }}</td>
                </tr>
                <tr>
                    <td align="left">Shipping & Handling</td>
                    <td align="right">
                        <span id="total_shipping">${{ shippingCharge.toFixed(2) }}</span>
                    </td>
                </tr>
                <tr>
                    <td align="left">Total Before Tax</td>
                    <td align="right">${{ beforeTax.toFixed(2) }}</td>
                </tr>
                <tr>
                    <td align="left">Total Tax</td>
                    <td align="right">${{ totalTax.toFixed(2) }}
                        <input type="hidden" name="totalTaxVal" value="0" />
                    </td>
                </tr>
                <tr v-if="$store.getters.isAuthenticated">
                    <td colspan="2">
                        <table width="100%">
                            <tr id="cuponpricearea">
                                <td align="left">Coupon Discount</td>
                                <td align="right" id="cuponprice">${{ couponPrice }}</td>
                            </tr>
                            <!-- <tr>
                                <td height="29"><strong>Have a Coupon Code?</strong></td>
                                <td align="right">
                                    <input type="radio" name="cupon" id="yes" value="1" @click="getCoupon" /> Yes
                                    <input type="radio" name="cupon" id="no" value="0" checked="checked"  @click="getCoupon"/> No
                                </td>
                            </tr> -->
                            <tr id="codearea" style="display:none">
                                <td colspan="2" align="right">
                                    <input type="text" name="cuponcode" id="cuponcode"  placeholder="Enter Code" />
                                    <button type="button" class="apply_btn" onclick="cuponFunc();">Apply</button>
                                    <div id="msgc"></div>
                                </td>
                        </tr>
                        </table>
                    </td>
                </tr>
                <tr style="border-top:1px solid #ccc">
                    <td height="38" align="left"><span class="grandTotal">Grand Total</span></td>
                    <td align="right"><span class="grandTotal">${{ grandTotal.toFixed(2) }}</span></td>
                </tr>               
            </table>
        </div> 
    </div>  
</template>

<script>
export default{
    name: "Addresses",
    props: ['itemTotal','shippingCharge','beforeTax','totalTax','couponPrice','grandTotal'] 
}
</script>