<template> 
    <div>
        <div class="sectionHeading">
            <h3><router-link :to="{ name: 'Fastival' }">
                Shop For Festival <i class="fa fa-arrow-right"></i></router-link>
            <br/><p>Curated collections hand-picked for you</p></h3>

            <div id="fastival-arrow">
                <button class="prev-btn" id="al" aria-label="left arrow"><i class="fa-solid fa-angle-left"></i></button>
                <button class="next-btn" id="al" aria-label="right arrow"><i class="fa-solid fa-angle-right"></i></button>
            </div>
        </div>
        <div class="row fastival_slider"> 
            <!-- class="col-6 col-sm-4 col-md-3 col-lg-2" -->
            <div  v-for="(item) in fastivals" :key="item.id">               
                <div class="fastival-thumbnail">
                    <router-link :to="{ path: '/fastival-product/'+item.slug }">
                        <img v-bind:src="item.image" v-bind:title="item.name" v-bind:alt="item.name" class="d-block w-100">
                        <h5 class="align-center">{{ item.name }}</h5>
                    </router-link>
                </div>   
            </div>         
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import axios from '@/utils/axiosConfig';

export default {
    name: 'Fastival', 
    
    data(){
        return{
            error: '',
            fastivals: [],
        }
    },
    mounted() {
        this.getFastival();
    },
    methods:{   
        getFastival() {
            axios.get(`fastivals`).then((response) => {
                if(response.data.success){
                    this.fastivals = response.data.data;
                    this.slider()
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        },
        slider(){
            setTimeout(function() {
                    let sliderCount = 5;
                if (window.matchMedia('screen and (max-width: 480px)').matches) {
                    sliderCount = 2;
                }
                else if (window.matchMedia('screen and (max-width: 768px)').matches) {
                        sliderCount = 3;
                }
                else if (window.matchMedia('screen and (max-width: 1024px)').matches) {
                        sliderCount = 4;
                }
                $('.fastival_slider').slick({
                    infinite: true,
                    speed: 300,
                    nextArrow: "#fastival-arrow .next-btn",
                    prevArrow: "#fastival-arrow .prev-btn",
                    slidesToShow: sliderCount,
                    arrows:true,
                    mobileFirst: true,
                    slidesToScroll: 1,
                    autoplay:true,                            
                }); 
            }, 0);     
        }
    },
};
</script>

