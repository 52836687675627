<template> 
    <div class="flashSale">
         <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                <div class="flash-card">
                    <div class="flash-title">Flash Sale</div>                    
                    <div class="flash-discount"></div> 
                    <router-link :to="{ name: 'FlashSaleGallery' }" class="view-all">View all</router-link>                   
                </div>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-12 col-12">
                <div class="row">
                    <div class="col-lg-2 col-md-3 col-sm-4 col-6" v-for="(item) in products" :key="item.id" style="margin:0; padding:0">
                        <div class="flash-sale-product">
                            <div class="flash-image">
                                <router-link :to="{ name: 'ProductDetails', params: {  code: item.code, slug: item.slug } }">
                                    <img v-bind:src="item.image" v-bind:title="item.name" v-bind:alt="item.name" class="productCard-img-top w-100">
                                </router-link>
                            </div>
                            <div>
                                <h5 class="flash-product-title">{{ item.name.substring(0,50)+".." }}</h5>
                                <div v-if="item.discount > 0">
                                    <span class="flash-product-price">${{ Number(item.price).toFixed(0) }}</span>
                                    <span class="flash-product-discount">
                                        <span style="text-decoration: line-through;">${{ Number(item.discount_amount).toFixed(0) }}</span>
                                        ({{ Number(item.discount).toFixed(0) }}{{ item.discount_type }} Off)
                                    </span>                                    
                                </div>
                                <div v-else><span class="flash-product-price">${{ Number(item.price).toFixed(0) }}</span></div>
                            </div>
                            <a href="javascript:void(0)" @click="$store.dispatch('addToCart', {product_id:item.id, seller_id:item.seller_id})" class="addToCart">Add to Cart</a>
                        </div>
                    </div>            
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import axios from '@/utils/axiosConfig';

export default {
    name: 'FlashSale', 
    
    data(){
        return{
            error: '',
            products: [],
        }
    },
    mounted() {
        this.getProduct();
    },
    methods:{   
        getProduct() {
            axios.get(`flash-sell-products`).then((response) => {
                if(response.data.success){
                    this.products = response.data.data;
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }
    },
};
</script>

