<template> 
    <div v-for="(item) in merchantCategories" :key="item.id">
        <div class="row" style="margin-bottom: 20px;">
            <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                <div class="blankCard justify-content-center align-items-center">
                    <h3> <small>{{ item.title }}</small><br/>
                        {{ item.merchant.business ? item.merchant.business.name : '' }}<br/><p>{{ item.details }} </p></h3>                    
                </div>
            </div> 
            <div v-for="(cat,index) in item.items" :key="index" style="margin:5px auto" v-bind:class="index == 4 ? 'col-lg-6 col-sm-12 col-12' : 'col-lg-3 col-sm-4 col-6'">
                <router-link :to="{ path: '/gallery/'+cat.category.slug }">
                    <div class="ad-title">{{ cat.category.name }}</div>
                    <img v-if="index == 4" v-bind:src="cat.category.banner" v-bind:title="cat.category.name" v-bind:alt="cat.category.name"  class="w-100" style="border-radius: 5px; height:260px">
                    <img v-else v-bind:src="cat.category.image" v-bind:title="cat.category.name" v-bind:alt="cat.category.name"  class="w-100" style="border-radius: 5px; max-height:262px;">
                </router-link>
            </div>

            <div class="col-12 col-sm-3 col-md-3 col-lg-3" style="margin-top: 5px;">
                <router-link :to="{ name: 'SellerGallery', params: { slug : item.merchant.business ? item.merchant.business.uniqueName : '#' } }">
                    <div class="viewAllCard d-flex justify-content-center heading">
                        View All Items of <br/>{{ item.merchant.name }}
                    </div>
                </router-link>
            </div> 
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axiosConfig';
export default {
    name: 'Marketplace', 
    
    data(){
        return{
            error: '',
            merchantCategories: [],
        }
    },
    mounted() {
        this.getProduct();
    },
    methods:{   
        getProduct() {
            axios.get(`merchant-categories`).then((response) => {
                if(response.data.success){
                    this.merchantCategories = response.data.data;
                    // console.log(this.merchantCategories)
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }
    },
};
</script>



