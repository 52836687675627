<template>
    <div class="page-wrapper" id="pageWrapper">
    <div class="page-body-wrapper">          
            <div class="page-body">
                <div class="container-fluid default-dash">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 style="text-align: center; margin: 30px;">Search for : {{ this.$route.query.s }}</h2>
                        </div>
                    </div>
                    <div v-if="error" class="text-danger align-items-center">{{ error }}</div>
                    <div class="row" v-if="products.length > 0">
                        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-0 m-0" v-for="(item) in products" :key="item.id">
                            <div class="productCard">
                                <div class="productImage">  
                                    <router-link :to="{ name: 'ProductDetails', params: {  code: item.code, slug: item.slug } }">
                                        <img v-bind:src="item.image" v-bind:title="item.name" v-bind:alt="item.name" class="productCard-img-top w-100">
                                    </router-link>
                                </div>
                                <div class="productCard-body">
                                    <div class="productDetails">
                                        <!-- <h5 class="productCard-title">{{ item.name }}</h5> -->
                                        <h5 class="productCard-title">{{ item.name.substring(0,50)+".." }}</h5>
                                        <div class="ProductDiscount">
                                            <span class="productDiscountPrice">${{ item.discount_amount }}</span>
                                            <span class="discountPercent jss229">{{ item.discount }}{{ item.discount_type }} Off</span>
                                        </div>
                                        <p class="ProductPrice">${{ item.price }}</p>
                                        <div class="rating">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star-half-stroke"></i>
                                        </div>
                                    </div>
                                    <a href="javascript:void(0)" @click="$store.dispatch('addToCart', {product_id:item.id, seller_id:item.seller_id})" class="btn btn-outline-primary d-grid">ADD TO CART</a>
                                </div>
                            </div>
                        </div>            
                    </div>
                    <div v-else class="text-center mt-5">
                        <h2 class="pt-5 text-danger">No Data Found</h2>
                    </div>
                </div>                
            </div>
        </div>
    </div>    
</template>


<script>
import axios from '@/utils/axiosConfig';

export default {
    name: 'Search',  
    data(){
        return{
            error: '',
            products: [],
        }
    },
    mounted() {  
        this.searchData(this.$route.query.s)      
    },
    methods:{  
        async searchData(keywords){  
            console.log(keywords)
            let response = await axios.get('filter-products?search_keywords='+keywords);
            if (response.data.success) {
                this.products = response.data.data.data;
            }
            else {
                this.error = 'URL Data Not Found';
            }
        }
    },
};
</script>

