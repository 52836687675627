<template> 
    <div>
        <div class="product-content">
            <h2 class="product-title">{{ products.name }}</h2>
            <h2 class="product-type">Product Code: {{ products.code }}</h2>
            <h6 class="product-type">Manufacturer: {{ products.manufacturer }}</h6>
            <a href="#">
                <h6 class="product-company">
                    Sold by
                    <router-link :to="{path: '/seller-store/' + merchants.uniqueName }">
                    <strong>{{ merchants.name }}</strong></router-link></h6>
            </a>
            <div class="product-rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
                <span> {{ products.ratings_count }}</span>
            </div>
            <div class="product-price-box">
                <div class="recently_view">
                    <i class="fa fa-box-archive" style="color: #999;"></i>
                    <span>More then {{ products.stock_qty }} available,  {{ totalsold }} items sold.</span>
                </div>
                <div class="product-price">
                    <p class="new-price">Best Price: <span>${{ products.price }} </span></p>
                    <p class="last-price">MRP: <span>${{ products.discount_amount }}</span><strong class="discount_off">(Get {{ products.discount }}% Off)</strong></p>
                </div>
            </div>
            <div class="quantity">
                    <input type="hidden" id="iteminstock" :value="products.stock_qty">
                <button class="btn minus-btn" type="button" @click="productINcDec('Minus',1)"><i class="fas fa-minus"></i></button>
                <input type="number" class="qty-input" id="detailsQuantity" step="1" min="1" max="1000" pattern="[0-9]*" value="1" @keyup="productQtyCheck(1)"/>
                <button class="btn plus-btn" type="button" @click="productINcDec('Plus',1)"><i class="fas fa-plus"></i></button>
                
            </div>
            <div style="color: #ff0000; padding:10px">{{ quantityErrorMsg }}</div>
            <div class="add-to-cart">
                <button type="button" class="btn_cart"  @click="$store.dispatch('addToCart', {product_id:products.id, seller_id:products.seller_id,quantity:'detailsQuantity'})">Add to Cart <i class="fas fa-shopping-cart"></i></button>
                <button type="button" class="btn_buy" @click="$store.dispatch('buyNow', {product_id:products.id, seller_id:products.seller_id})">Buy it now</button>
                <!-- $store.dispatch('buyNow', {product_id:products.id, seller_id:products.seller_id}) -->
            </div>
            <div class="additional_info">
                <div class="additional-title">
                    <span class="addition_heading">Shipping Details</span>
                </div>
                <p v-html="products.shipping_details"></p>
            </div>
            <div class="additional_info">
                <div class="additional-title">
                    <span class="addition_heading">Highlights</span>
                </div>
                <p v-html="products.features"></p>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Contents',
    props: ['products','merchants','totalsold'],
    data(){
        return{
            error: '',
            quantityErrorMsg: '', 
        }
    },
    methods:{  
        productINcDec(pid, minqty) {
            var detailsQuantity = document.getElementById('detailsQuantity').value;
            var iteminstock = document.getElementById('iteminstock').value;
            if (pid == "Plus") {
                if (parseInt(detailsQuantity) >= parseInt(iteminstock)) {
                    this.quantityErrorMsg = 'Sorry! limited stock. Plese select ' + parseInt(iteminstock) + ' qty or less';
                    document.getElementById('detailsQuantity').value = parseInt(detailsQuantity);
                } else {
                    document.getElementById('detailsQuantity').value = parseInt(detailsQuantity) + 1;
                    this.quantityErrorMsg = '';
                }
            } else if (pid == "Minus") {
                if (document.getElementById('detailsQuantity').value != parseInt(minqty)) {
                    document.getElementById('detailsQuantity').value = parseInt(detailsQuantity) - 1;
                    this.quantityErrorMsg = '';
                } else {
                    this.quantityErrorMsg = 'Minimum quantity is Selected';
                }
            }
        },
        productQtyCheck(minqty) {
            var detailsQuantity = document.getElementById('detailsQuantity').value;
            var iteminstock = document.getElementById('iteminstock').value;
            if (parseInt(detailsQuantity) < parseInt(minqty)) {
                this.quantityErrorMsg = 'Minimum quantity is Selected';
                document.getElementById('detailsQuantity').value = minqty;
            } else if (parseInt(detailsQuantity) > parseInt(iteminstock)) {
                this.quantityErrorMsg = 'Sorry! limited stock. Plese select ' + parseInt(iteminstock) + ' qty or less';
                document.getElementById('detailsQuantity').value = minqty;
            } else {
                this.quantityErrorMsg = '';
                return false;
            }
        }    
    },
};
</script>

