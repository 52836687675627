<template>
    <div>
        <div id="newAddress" style="display: none;">
            <form @submit.prevent="shippingAddress">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-card">
                            <input type="text" v-model="shipping.address" placeholder="Street Address:"/>
                            <input type="text" v-model="shipping.city" placeholder="City"/>
                            <input type="text" v-model="shipping.zipcode" placeholder="Postal/ZIP Code:"/>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-card">
                            <select v-model="shipping.country" @change="getStates($event)">
                                <option value="">Country</option>
                                <option v-for="(country) in countries" :key="country.id" v-bind:value="country.id">{{ country.name }}</option>
                            </select>

                            <select v-model="shipping.state">
                                <option value="">State/Province</option>
                                <option v-for="(state) in states" :key="state.id" v-bind:value="state.id">{{ state.name }}</option>
                            </select>
                            
                            <select v-model="shipping.property_type">
                                <option value="">Property Type</option>
                                <option value="Home">Home</option>
                                <option value="Office">Office</option>
                                <option value="Flat">Flat</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <input type="submit"  class="action-button" value="Next"/>
                        <a style="cursor: pointer; text-decoration: underline;" @click="getAddress('existing')">Existing Address</a>
                    </div>
                </div>                                        
            </form>                                          
        </div>
        <div id="existingAddress">
            <div class="row">
                <span v-if="loading" class="fa-3x fa fa-spinner fa-spin"></span>
                <div class="col-sm-4" style="margin: 0; padding: 0" v-for="(addData) in shippingAddresses" :key="addData.id">
                    <div class="customCard" @click="selectShipping(addData.id)">
                            <strong>{{ addData.full_name}} </strong><br>
                            {{ addData.contact}}<br>
                            {{ addData.address}}<br>
                            {{ addData.city }},
                            {{ addData.states ? addData.states.name : ''}},
                            {{ addData.zipcode }}<br>
                            {{ addData.countries ? addData.countries.name : '' }}
                    </div>
                </div>
                <div class="col-sm-4" style="margin: 0; padding: 0">
                    <div class="customCard d-flex justify-content-center heading" @click="getAddress('new')">
                        + New shipping address
                    </div>
                </div>                                               
            </div>
        </div>  
    </div>  
</template>

<script>
import $ from 'jquery'
// import axios from '@/utils/axiosConfig';
import axios from '@/utils/axiosConfig';

export default{
    name: "Addresses",
    data(){
        return {
            loading: true,
            merchants: [],
            rates: [],
            shippingAddresses: [],
            countries: [],
            states: [],
            ok:'',          
            errors: [],
            shipping: {                
                address: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
                property_type: ""
            }
        }
    },
    mounted() {
        // console.log(this.$store.getters.StateUser)
        if(this.$store.getters.isAuthenticated){
            this.getShippingList()
            this.getCounties()   
        }
        else{
            this.$router.push({ path: '/checkout-login' })
        }
    },
    methods: {
        getAddress(type){
            if ('new' == type){
                $("#newAddress").show()
                $("#existingAddress").hide()
            }
            else {
                $("#newAddress").hide()
                $("#existingAddress").show()
            }
        },
        getCounties() {   
            axios.get(`countries`).then((response) => {
                
                if (response.data.success) {
                    this.countries = response.data.data;
                }
                else {
                    this.error = 'API Data Not Found';
                }
            });
        },
        getStates(event) {     
            axios.get(`statesByCountry/` + event.target.value).then((response) => {
                if (response.data.success) {
                    this.states = response.data.data; 
                }
                else {
                    this.error = 'API Data Not Found';
                }
            });
        },
        getShippingList() {   
            this.loading = true
            setTimeout(() => {
                axios.get(`shipping`).then((response) => {
                    
                    if (response.data.success) {
                        this.loading = false
                        this.shippingAddresses = response.data.data;
                    }
                    else {
                        this.loading = false
                        this.error = 'API Data Not Found';
                    }
                });
            }, 2000)
        },
        async shippingAddress() {
            try {
                let response = await axios.post(`shipping-or-billing`, {
                    address: this.shipping.address,
                    city: this.shipping.city,
                    state: this.shipping.state,
                    zipcode: this.shipping.zipcode,
                    country: this.shipping.country,
                    property_type: this.shipping.property_type
                });

                this.status = response.data.status
                this.ok = response.data.message
                this.errors = !response.data.status ? response.data.error : ''
                
                if (response.data.success) {
                    this.loading = false
                    localStorage.setItem('shipping_id', response.data.data.id)                    
                    this.$router.push('/shipping-cost')
                    this.$router.go(0)
                }
            }
            catch (e) {
                this.error = e.message
            }   
        },
        selectShipping(selectedShippingId) {   
            localStorage.setItem('shipping_id', selectedShippingId)                    
            this.$router.push('/shipping-cost')
        }
    }
}
</script>