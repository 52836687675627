import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // Backend API URL
  // timeout: 5000, // Optional: request timeout
  withCredentials: true // Include credentials with requests
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Extract JWT token from cookies
    const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('jwt='))
      ?.split('=')[1];

      // console.log(document.cookie)
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    console.log('Request Config:', config); // Log the request config
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    console.log('Response:', response); // Log the response
    return response;
  },
  (error) => {
    if (error.response) {
      console.error('Response error:', error.response.data);
      if (error.response.status === 401) {
        window.location.href = '/login';
      }
    } else if (error.request) {
      console.error('Network error:', error.message);
    } else {
      console.error('Error setting up request:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
