<template> 
    <div class="container-fluid" id="grad1">
        <div class="row justify-content-center mt-0">
        <div class="col-sm-5">
            <div class="card p-4 mt-5 mb-5" style="border: 1px solid #eaeaea;">
                <form @submit.prevent="handleLogin" id="msform">                  
                    <div class="form-title"><h2>Login</h2></div>
                    <ul v-if="showError">
                        <li v-for="(er) in errors" :key="er.id" class="errors text-center">
                            {{ er }}
                        </li>
                    </ul>
                    <div class="text-success">{{ ok }}</div>                    
                    <div class="form-group row">
                        <div v-if="status" class="text-success text-center mb-2">{{ message }}</div>
                        <div v-else-if="!status" class="text-danger text-center mb-2">{{ message }}</div>
                        <div class="row form-card p-1">
                            <div class="col-sm-2 d-lg-block d-none d-sm-none mt-2">
                                <label for="customerEmail">Email</label>
                            </div>
                            <div class="col-sm-10">
                                <input type="email" v-model="form.username" id="customerEmail" placeholder="Enter Registered Email" autocomplete="off">
                            </div>
                        </div>
                        <div class="row form-card p-1">
                            <div class="col-sm-2 d-lg-block d-none d-sm-none mt-2">
                                <label for="customerPassword">Password</label>
                            </div>
                            <div class="col-sm-10">
                                <input type="password" v-model="form.password" id="customerPassword" placeholder="Enter Password" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" style="margin-top: 40px;">   
                        <div class="col-12 col-sm-12 col-md-7 col-lg-7 mt-2">                               
                                <li style="display: block;">Don't have an account?
                                    <router-link :to="{ name : 'SignUp'}" class="cursor anchor">Signup here</router-link></li>
                        </div>  
                        <div class="col-12 col-sm-12 col-md-5 col-lg-5 mt-2">
                            <button type="submit" class="btn btn-primary">Sign In</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </div>
</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: 'SignIn',
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
            status : '',
            message: '',            
            errors: [],
            showError: false
        }
    },
    methods: {
        ...mapActions(["Login"]),
        async handleLogin() {
            try {
                await this.Login(this.form);
                if(this.$store.getters.isAuthenticated){
                    this.showError = false
                    this.$router.push({ path: '/profile' })
                }
                else{
                    this.message = this.$store.getters.StateMessage
                    this.errors = this.$store.getters.StateErrors
                    // console.log( this.$store.getters.StateMessage, this.$store.getters.StateErrors)
                }
            } catch (error) {
                console.log(error)
                this.showError = true
            }
        }
    }
}
</script>