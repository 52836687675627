<template>
    <div class="page-wrapper" id="pageWrapper">
        <div class="page-body-wrapper seller-wrapper">  
            <div class="white-bg">      
                <div class="container seller-padding">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                            <h3>Sell with flavors, Earn with passion</h3>
                            <p class="mt-4 mb-5">Elevate your culinary business: reach a world of food 
                                enthusiasts and boost your success with us!</p>
                                <router-link :to="{name : 'MerchantSignUp'}" class="orange-button">Sign Up</router-link>
                                <a href="http://ethnicmeal.com/merchant/login" target="_blank" class="orange-text">Sign In to Marketplace ></a>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12">
                            <img src="../assets/seller-person.png" class="w-100">
                        </div>
                    </div>
                </div>
            </div>  
            <div class="orange-bg">
                <div class="container seller-wlecome seller-padding">
                    <img src="../assets/icon/seller_carrot_icon.png" class="icon-b mb-4">
                    <h2>Welcome to Ethnic Meal: <br/> Where Authentic Culinary Creations Shine!</h2>
                </div>
            </div>
            <div class="white-bg">      
                <div class="container seller-padding">
                    <div class="col-sm-12 text-center mb-4">                        
                        <h4>Start Selling, It`s <img src="../assets/easy.png" class="icon-b mt-4"></h4>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-12 text-center mt-5">
                            <img src="../assets/store1.png" class="w-75">
                            <p class="text-center">Create Your Seller Account</p>
                        </div>                        
                        <div class="col-lg-3 col-md-3 col-sm-12 text-center mt-5">
                            <img src="../assets/store2.png" class="w-75">
                            <p class="text-center">Listing Your Products</p>
                        </div>                        
                        <div class="col-lg-3 col-md-3 col-sm-12 text-center mt-5">
                            <img src="../assets/store3.png" class="w-75">
                            <p class="text-center">Set Your Policies</p>
                        </div>                        
                        <div class="col-lg-3 col-md-3 col-sm-12 text-center mt-5 mb-5">
                            <img src="../assets/store4.png" class="w-75">
                            <p class="text-center">Grow Your Business</p>
                        </div>                        
                    </div>
                    <div class="col-sm-12 text-center mt-5">
                        <router-link :to="{name : 'MerchantSignUp'}" class="orange-button">Create Seller Account</router-link>
                    </div>
                </div>
            </div>
            <div class="sky-bg">      
                <div class="container seller-padding">
                    <div class="row">
                        <div class="col-sm-5 mb-5">
                            <h4>Seamless, Profitable & Worry-Free</h4>
                            <p class="mt-4 mb-5">
                                Celebrating global cuisines with authentic products and kitchen essentials. 
                                Join us as a seller to share your culinary treasures.
                            </p>
                            <router-link :to="{name : 'MerchantSignUp'}" class="orange-button">Start Selling Today</router-link>
                        </div>
                        <div class="col-sm-1">&nbsp;</div>
                        <div class="col-sm-6">                            
                            <ul>
                                <li>
                                    <img src="../assets/icon/icon1.png" class="icon-s">
                                    <div><strong>Simple, Transparent, Secure:</strong> We prioritise simplicity and transparency in every transaction, ensuring your peace of mind.</div>
                                </li>
                                <li>
                                    <img src="../assets/icon/icon2.png" class="icon-s">
                                    <div><strong>No Additional Monthly Fees:</strong> Forget about pesky monthly fees eating into your profits. Ethnic Meal is designed to help you thrive without hidden costs.</div>
                                </li>
                                <li>
                                    <img src="../assets/icon/icon3.png" class="icon-s">
                                    <div><strong>Secure Transactions:</strong> Trust is at the heart of our platform. Rest assured, your transactions are safe and secure.</div>
                                </li>
                                <li>
                                    <img src="../assets/icon/icon4.png" class="icon-s">
                                    <div><strong>Automatic Deposits:</strong> Enjoy the convenience of automatic deposits - your hard-earned money is just a click away.</div>
                                </li>
                                <li>
                                    <img src="../assets/icon/icon5.png" class="icon-s">
                                    <div><strong>Seller Protection:</strong> We've got your back. Ethnic Meal provides seller protection to safeguard your interests.</div>
                                </li>
                                <li>
                                    <img src="../assets/icon/icon6.png" class="icon-s">
                                    <div><strong>$0 Listing Fee:</strong> Start showcasing your products for free. Your listings stay active until they find a happy home.</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="white-bg">      
                <div class="container seller-padding">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 p-0 m-0">
                            <img src="../assets/seller-chef.png" class="w-100">
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-12 p-0 m-0">
                            <div class="orange-light-bg">                              
                                <h5>Culinary adventure that goes beyond selling!</h5>
                                <p class="mt-4 mb-5">It's about connecting with food lovers worldwide. Embark on your flavourful journey today, and let your unique flavours shine, creating connections and memorable experiences!</p>
                                <router-link :to="{name : 'MerchantSignUp'}" class="orange-button">Start Building Your Network ></router-link>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>    
            <div class="white-bg">      
                <div class="container seller-padding">
                    <div class="col-sm-12 text-left mb-5">                        
                        <h4>Why Sellers Choose Us</h4>
                        <p>See what sets us apart and read more about our platform</p>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="seller-comment-bg mb-4">
                                <p>
                                Ethnic Meal has given me the freedom to be my own boss. I love the flexibility it offers - 
                                I can choose my schedule and workload, making it an enjoyable way to earn a living.</p>
                                <div class="row">
                                    <div class="col-sm-2">
                                        <img src="../assets/andrew-profile.webp" alt="andrew-profile" class="icon-s">
                                    </div>
                                    <div class="col-sm-10">
                                        <div class="orange-text p-0">Andrew S.</div>
                                        <div>Syracuse, NY</div>
                                    </div>
                                </div>
                            </div>      
                        </div> 
                        <div class="col-sm-4">
                            <div class="seller-comment-bg mb-4">
                                <p>
                                    Selling on Ethnic Meal is not just a job; it's a delightful adventure. I appreciate the ability to set my hours, whether I want to work long, 
                                    productive days or take it easy with just a couple of deliveries - it all depends on my goals for the week.
                                </p>
                                <div class="row">
                                    <div class="col-sm-2">
                                        <img src="../assets/jocelyn-profile.webp" alt="andrew-profile" class="icon-s">
                                    </div>
                                    <div class="col-sm-10">
                                        <div class="orange-text p-0">Jocelyn P.</div>
                                        <div>Temecula, CA</div>
                                    </div>
                                </div>
                            </div>      
                        </div> 
                        <div class="col-sm-4">
                            <div class="seller-comment-bg">
                                <p>
                                    Ethnic Meal has been a game-changer for me. I've found the perfect balance between work and life. 
                                    With the flexibility to determine my own schedule and deliveries, I'm enjoying my work like never before.
                                </p>
                                <div class="row">
                                    <div class="col-sm-2">
                                        <img src="../assets/raynah-profile.webp" alt="andrew-profile" class="icon-s">
                                    </div>
                                    <div class="col-sm-10">
                                        <div class="orange-text p-0">Raynah W.</div>
                                        <div>Chattanooga, TN</div>
                                    </div>
                                </div>
                            </div>      
                        </div>  
                    </div>
                </div>
            </div> 
            <div class="join-marketplace">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-9">
                            <h5>Your Exceptional Products, Our Vast Customer Network</h5>
                            <p>Become a Professional Seller in Our Community Now!</p>
                        </div>
                        <div class="col-sm-3 mt-5">
                            <router-link :to="{name : 'MerchantSignUp'}" class="white-button">Join Marketplace</router-link>
                        </div>
                    </div>
                </div>
            </div>      
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Seller', 
    };
</script>