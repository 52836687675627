<template> 
    <div class="container-fluid" id="grad1">
        <div class="row justify-content-center mt-0">
            <div class="col-11 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2">
                <div class="card px-0 pt-4 pb-0 mt-3 mb-3" style="border: 1px solid #eaeaea;">
                    <h2><strong>Merchant Registration</strong></h2>
                    <p>Fill all form field to go to next step</p>
                    <ul>
                        <li v-for="(er) in errors" :key="er.id" class="errors">
                            {{ er }}
                        </li>
                    </ul>
                    <div class="text-success">{{ ok }}</div>

                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <form @submit.prevent="registration" id="msform" enctype="multipart/form-data">   
                                <ul id="progressbar">
                                    <li class="active" id="account"><strong>Account Details</strong></li>
                                    <li id="personal"><strong>Personal Details</strong></li>
                                    <li id="address"><strong>Address</strong></li>
                                    <li id="payment"><strong>Business Information</strong></li>
                                    <li id="confirm"><strong>Payment Details</strong></li>
                                </ul>
                                <fieldset>
                                    <div class="form-card">
                                        <input type="email" v-model="form.email" placeholder="Email Address"/>
                                        <input type="text" v-model="form.username" placeholder="Username"/>
                                        <input type="password" v-model="form.password" placeholder="Password"/>
                                        <input type="password" v-model="form.cpassword" placeholder="Confirm Password"/>
                                    </div>
                                    <input type="button" class="next action-button" value="Next"/>
                                </fieldset>
                                <fieldset>
                                    <div class="form-card">
                                        <input type="text" v-model="form.name" placeholder="Full Name"/>
                                        <input type="tel" v-model="form.mobile" placeholder="Contact Number"/>
                                        <input type="tel" v-model="form.emergency_contact" placeholder="Secondary Contact Number"/>
                                        <input type="email" v-model="form.alternate_email" placeholder="Secondary Email"/>
                                    </div>
                                    <input type="button" class="previous action-button-previous" value="Previous"/>
                                    <input type="button" class="next action-button" value="Next"/>
                                </fieldset>
                                <fieldset>
                                    <div class="form-card">
                                        <input type="text" v-model="form.address" placeholder="Street Address:"/>
                                        <input type="text" v-model="form.city" placeholder="City"/>
                                        <input type="text" v-model="form.state" placeholder="State/Province"/>
                                        <input type="text" v-model="form.zipcode" placeholder="Postal/ZIP Code:"/>
                                        <input type="text" v-model="form.country_id" placeholder="Country:"/>
                                    </div>
                                    <input type="button"  class="previous action-button-previous" value="Previous"/>
                                    <input type="button"  class="next action-button" value="Next"/>
                                </fieldset>
                                <fieldset>
                                    <div class="form-card">
                                        <input type="text" v-model="form.bname" placeholder="Business Name"/>
                                        <input type="tel" v-model="form.bcontact" placeholder="Business Phone Number"/>
                                        <input type="text" v-model="form.baddress" placeholder="Street Address"/>
                                        <input type="tel" v-model="form.bcity" placeholder="City"/>
                                        <input type="tel" v-model="form.bcountry_id" placeholder="Country"/>
                                        <input type="tel" v-model="form.bstate" placeholder="State/Province"/>
                                        <input type="tel" v-model="form.bzipcode" placeholder="Postal/ZIP Code"/>
                                        <input type="url" v-model="form.website" placeholder="Website"/>
                                        <input type="tel" v-model="form.business_type" placeholder="Business Model"/>
                                        <input type="file" v-on:change="handleFileUpload()" id="logo" ref="logo" placeholder="Logo"/>
                                        <input type="text" v-model="form.document_type" placeholder="Document Type"/>
                                        <input type="text" v-model="form.headline" placeholder="Document headline"/>
                                        <input type="file" v-on:change="handleFileUpload()" id="document" ref="document" placeholder="Document"/>
                                    </div>
                                    <input type="button" class="previous action-button-previous" value="Previous"/>
                                    <input type="button" class="next action-button" value="Next"/>
                                </fieldset>
                                <fieldset>
                                    <div class="form-card">
                                        <select v-model="form.payment_method" id="payment_method" class="form-control">
                                            <option value="Credit/Debit Card">Credit/Debit Card</option>
                                            <option value="PayPal">PayPal</option>
                                            <option value="Bank Transfer">Bank Transfer</option>
                                            <option value="Digital Wallet">Digital Wallet (e.g., Apple Pay, Google Wallet)</option>
                                            <option value="Check">Check</option>
                                        </select>
                                    </div>
                                    <input type="button" class="previous action-button-previous" value="Previous"/>
                                    <input type="submit" class="action-button btn btn-success" value="Submit"/>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>import $ from 'jquery'
import '../../assets/css/forms.css'
import axios from '@/utils/axiosConfig';

export default {
name: 'SignUp',  
data(){
    return{
        form:{
            email: "",
            username: "",
            password: "",
            cpassword: "",
            name: "",
            mobile: "",
            emergency_contact: "",
            alternate_email: "",
            address: "",
            city: "",
            state: "",
            zipcode: "",
            country_id: "",
            bname: "",
            bcontact: "",
            baddress: "",
            bcity: "",
            bcountry_id: "",
            bstate: "",
            bzipcode: "",
            website: "",
            business_type: "",
            payment_method: "",
            document_type: "",
            headline: "",
            logo: "",
            document: "",
        },
        errors: [],
        ok : ''
    }
},    
methods:{  
    registration: function (e) {
        axios.post(`register-vendor`, this.form)
            .then((response) => {
                if(response.data.status){
                    this.ok = response.data.message;
                    this.form = '';
                    this.errors = ''
                }
                else{              
                    // console.log(response.data.error);
                    if (response.data.error !== undefined) {
                        this.errors = response.data.error;
                    }
                }             
            })            
            .catch(function(error){            
                console.log(error);
            });
            e.target.reset();
    },
    handleFileUpload(){
        this.form.logo = this.$refs.logo.files[0];
        this.form.document = this.$refs.document.files[0];
    },
    actionControl() {
        var current_fs, next_fs, previous_fs; //fieldsets
        var opacity;
        $(".next").on("click", function () {
            current_fs = $(this).parent();
            next_fs = $(this).parent().next();
            $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
            next_fs.show();
            current_fs.animate({
                opacity: 0
            }, {
                step: function (now) {
                    opacity = 1 - now;

                    current_fs.css({
                        'display': 'none',
                        'position': 'relative'
                    });
                    next_fs.css({
                        'opacity': opacity
                    });
                },
                duration: 600
            });
        });

        $(".previous").on("click", function () {

            current_fs = $(this).parent();
            previous_fs = $(this).parent().prev();

            $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
            previous_fs.show();
            current_fs.animate({
                opacity: 0
            }, {
                step: function (now) {
                    opacity = 1 - now;
                    current_fs.css({
                        'display': 'none',
                        'position': 'relative'
                    });
                    previous_fs.css({
                        'opacity': opacity
                    });
                },
                duration: 600
            });
        });

        $('.radio-group .radio').on("click", function () {
            $(this).parent().find('.radio').removeClass('selected');
            $(this).addClass('selected');
        });

        $(".submit").on("click", function () {
            return false;
        })
    }
},
mounted() { 
    this.actionControl()
}
};
</script>

