<template> 
    <div class="container-fluid" id="grad1">
        <div class="row justify-content-center mt-0">
            <div class="col-11 col-sm-10 col-md-10 col-lg-10 text-center p-0 mt-3 mb-2">
                <div class="card px-0 pt-4 pb-0 mt-3 mb-3" style="border: 1px solid #eaeaea;">
                    <ul>
                        <li v-for="(er) in errors" :key="er.id" class="errors">
                            {{ er }}
                        </li>
                    </ul>
                <div class="text-success">{{ ok }}</div>
                <div class="text-danger">{{ error }}</div>                        
                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <div id="msform" class="container">   
                                <ul id="progressbar">
                                    <li  class="active" id="account" style="width: 25%;"><strong>Sign In</strong></li>                                        
                                    <li id="personal" v-bind:class="$store.getters.isAuthenticated ? 'active' : ''" style="width: 25%;"><strong>Shipping Details</strong></li>
                                    <li id="shipping" v-bind:class="$store.getters.isAuthenticated && shipping_id ? 'active' : ''" style="width: 25%;"><strong>Shipment Cost</strong></li>
                                    <li id="payment" style="width: 25%;"><strong>Payment</strong></li>
                                </ul>                                
                                <fieldset>
                                    <div class="row">
                                        <span v-if="loading" class="fa-3x fa fa-spinner fa-spin"></span>
                                        <div class="col-sm-9">
                                            <div id="shippingChargeErrMsg"></div>
                                            <div class="card carts-area" v-for="(seller) in merchants" :key="seller.id">
                                                <div class="row">
                                                    <div class="col-sm-7">
                                                        <div class="store-name">{{ seller.name }}</div>
                                                        <div class="card-body">
                                                            <div class="row border-top" v-for="(item) in seller.carts" :key="item.id">
                                                                <div class="col-sm-4">
                                                                    <img v-bind:src="item.product.image" v-bind:title="item.product.name" v-bind:alt="item.product.name">
                                                                </div>
                                                                <div class="col-sm-8">
                                                                    <div class="product-details">
                                                                        <h5 class="product-name">{{ item.product.name.substring(0,20)+".." }}</h5>
                                                                        <div class="qty-area">QTY: {{ item.quantity }} </div>
                                                                        <div class="price">${{ Number(item.product.product_inventory.price).toFixed(2) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-5">
                                                        <h5>Choose your delivery option</h5>
                                                        <table class="table shipping_options">
                                                            <tr v-for="rate in rates[seller.id]" :key="rate.id" >
                                                                <td>
                                                                    <img v-bind:src="rate.provider_image_75">
                                                                    {{ rate.provider }} ({{ rate.duration_terms }})
                                                                </td>
                                                                <td width="30%">
                                                                    <div style="float:left">
                                                                        <input type="radio" :name="'shipping_rate['+seller.id+']'" :value="rate.amount" @click="getShippingCharge(seller.id)">
                                                                    </div>
                                                                    <div style="float:left">${{ rate.amount +' '+ rate.currency }}</div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-3">
                                            <OrderSummery 
                                                :itemTotal="itemTotal" 
                                                :shippingCharge="shippingCharge" 
                                                :beforeTax="beforeTax"
                                                :couponPrice="couponPrice"
                                                :grandTotal="grandTotal"
                                                :totalTax="totalTax"/>
                                        </div>
                                    </div>
                                    <button type="buttton" disabled class="d-none" id="paymentBtn" @click="getPayment()">Payment</button>
                                </fieldset>                   
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import '../../assets/css/forms.css'
import axios from '@/utils/axiosConfig';
import OrderSummery from '@/components/checkout/OrderSummery.vue'

export default {
    name: 'ShippingCost', 
    components:{
        OrderSummery,
    },
    data(){
        return {
            loading: false,
            status : '',
            guest_id : '',
            shipping_id : '',
            ok:'',          
            errors: [],
            merchants: [],
            rates: [],
            error: '',
            merchantWiseShipping: [],
            sellerIds: [],
            shippingCharge: 0,
            itemTotal: 0,
            beforeTax: 0,
            totalTax: 0,
            couponPrice: 0,
            grandTotal: 0,
            itemCountTotal: 0,
        }
    },    
    mounted() {    
        $("#paymentBtn").attr('disabled', true);
        if(this.$store.getters.isAuthenticated){
           this.shipping_id = localStorage.getItem('shipping_id')
            if (this.shipping_id) {
                this.loading = false
                this.callShippingApi(this.shipping_id,'user');
                this.calculation()
            }
        }
        else if(localStorage.getItem('isGuest')){
           this.guest_id = localStorage.getItem('guest_id')
            if (this.guest_id) {
                this.loading = false
                this.callShippingApi(this.guest_id,'guest');
                this.calculation()
            }
        }
        else{
            this.loading = false
            this.$router.push({ path: '/checkout-login' })
        }

        
    },
    methods: {        
        getShippingCharge: function (id) {
            ////////////// Get all Checked Shipping charge /////////////
            var allrate = [];
            $("input:radio:checked").each(function () {
                allrate.push($(this).prop('value'));
            });

            ////////////// Remove existing merchant shipping charge form array /////////////
            var result  = $.grep(this.merchantWiseShipping, function(e){ 
                return e.id != id; 
            });
            this.merchantWiseShipping = result;

            ////////////// Add merchant shipping charge into array /////////////
            var checkedvalue = $("input[name='shipping_rate[" + id + "]']:checked").val();
            var rData = { "id": id, "charge": checkedvalue }
            this.merchantWiseShipping.push(rData);                      
            localStorage.setItem('merchant_shipping', JSON.stringify(this.merchantWiseShipping))
                        
			// var couponChecked = $("input[name='cupon']:checked").val()
            var couponChecked = 0;
            let totalShipping = allrate.reduce((accumulator, currentVal) => parseFloat(accumulator) + parseFloat(currentVal));
            this.shippingCharge = totalShipping - couponChecked;     
            this.calculation()
        },
        calculation: function () {
            this.itemTotal = parseFloat(localStorage.getItem('itemTotal'))
            this.beforeTax = parseFloat(this.itemTotal) + parseFloat(this.shippingCharge);
            this.grandTotal = (parseFloat(this.itemTotal) + parseFloat(this.shippingCharge) + parseFloat(this.totalTax)) - this.couponPrice;

            localStorage.setItem('beforeTax', this.beforeTax)
            localStorage.setItem('totalTax', this.totalTax)
            localStorage.setItem('shippingCharge', this.shippingCharge)
            localStorage.setItem('grandTotal', this.grandTotal)  
        },
        /* getCoupon: function () {
            if($("input[name='cupon']").is(':checked')){
                var couponChecked = $("input[name='cupon']:checked").val()
                if(couponChecked){
                    $("#codearea").show();
                }
                else{
                    $("#codearea").hide();
                }
            }
        },    */ 
        async callShippingApi(shippingId, userType){            
            try {
                this.loading = true
                let response = await axios.get(`carts/` + shippingId + '/' + userType);
                if (response.status == 200 && response.data.success) {
                    this.loading = false
                    localStorage.setItem('shippingApiData', JSON.stringify(response.data.data))
                    let shippingApiData = JSON.parse(localStorage.getItem('shippingApiData'))
                    this.merchants = shippingApiData.carts;
                    this.rates = shippingApiData.shipment;  
                    $("#paymentBtn").attr('disabled', false);
                    $("#paymentBtn").removeClass('d-none');
                    $("#paymentBtn").addClass('action-button');
                }
                else {
                    this.loading = false
                    this.error = 'URL Data Not Found';                    
                    $("#paymentBtn").attr('disabled', false);
                    $("#paymentBtn").removeClass('d-none');
                }
            } catch (err) {
                $("#paymentBtn").attr('disabled', false);
                $("#paymentBtn").removeClass('d-none');
                this.loading = false
                // throw new Error(err)
                console.log(err)
            }       
        },
        getPayment() {  
            if(this.merchantWiseShipping.length == this.merchants.length){
                this.$router.push('/payment')
            }
            else{
                $("#shippingChargeErrMsg").html("Please select all items shipping charge")
                $("#shippingChargeErrMsg").addClass("alert alert-danger")
                document.getElementById("shippingChargeErrMsg").scrollIntoView({ behavior: 'smooth'});
                //  $("body").animate({ scrollTop: 0 }, "slow");
            }
        }
    }
};
</script>   