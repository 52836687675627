import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import FlashMessage from '@smartweb/vue-flash-message';

// axios.defaults.withCredentials = true
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// axios.defaults.headers.common['Authorization'] = "Bearer  " + store.getters.StateToken;

import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/vendors/all.min.css'
import './assets/css/vendors/bootstrap.min.css'
import './assets/css/style.css'
import './assets/css/temp.css'
import './assets/css/responsive.css'    
import './assets/slick/slick.css'
import './assets/css/custom.css'
import './assets/css/custom_responsive.css'
// import './assets/menu/style.css'
import './assets/js/bootstrap.min.js'
import './assets/js/scripts.js'
import './assets/slick/slick.js'
import './assets/menu/script.js'

createApp(App)
    .use(store, FlashMessage)
    .use(router).mount('#app')