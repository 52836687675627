<template>
    <div>
        <div class="card px-0 pt-4 pb-0 mt-3 mb-3" style="border: 1px solid #eaeaea;">
            <h2><strong>Guest Address</strong></h2>
            <p>Fill all form field to go to next step</p>
            <ul>
                <li v-for="(er) in errors" :key="er.id" class="errors">
                    {{ er }}
                </li>
            </ul>
            <form @submit.prevent="guestLogin">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-card">
                            <input type="text" v-model="shipping.fullname" placeholder="Fullname"/>
                            <input type="tel" v-model="shipping.contact" placeholder="Contact No"/>
                            <input type="email" v-model="shipping.email" placeholder="Email"/>
                            <input type="text" v-model="shipping.address" placeholder="Street Address:"/>
                            <input type="text" v-model="shipping.city" placeholder="City"/>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-card">
                            <input type="text" v-model="shipping.zipcode" placeholder="Postal/ZIP Code:"/>
                            <select v-model="shipping.country" @change="getStates($event)">
                                <option value="">Country</option>
                                <option v-for="(country) in countries" :key="country.id" v-bind:value="country.id">{{ country.name }}</option>
                            </select>

                            <select v-model="shipping.state">
                                <option value="">State/Province</option>
                                <option v-for="(state) in states" :key="state.id" v-bind:value="state.id">{{ state.name }}</option>
                            </select>
                            
                            <select v-model="shipping.property_type">
                                <option value="">Property Type</option>
                                <option value="Home">Home</option>
                                <option value="Office">Office</option>
                                <option value="Flat">Flat</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                        <a href="#" class="btn guestBtn" @click="pageReload()">Continue as a registered user</a>

                    </div>
                </div>                                        
            </form>   
        </div>                                       
    </div>        
</template>

<script>
import axios from '@/utils/axiosConfig';

export default{
    name: "Addresses",
    data(){
        return {
            loading: true,
            rates: [],
            countries: [],
            states: [],
            ok:'',          
            errors: [],
            shipping: {                
                fullname: "",
                contact: "",
                email: "",
                address: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
                property_type: ""
            }
        }
    },
    mounted() {
        this.getCounties()   
    },
    methods: {   
        pageReload(){
            window.location.reload()
        }  ,  
        getCounties() {   
            axios.get(`countries`).then((response) => {
                
                if (response.data.success) {
                    this.countries = response.data.data;
                }
                else {
                    this.error = 'API Data Not Found';
                }
            });
        },
        getStates(event) {     
            axios.get(`statesByCountry/` + event.target.value).then((response) => {
                if (response.data.success) {
                    this.states = response.data.data; 
                }
                else {
                    this.error = 'API Data Not Found';
                }
            });
        },        
        async guestLogin() {
            try {
                
                 let response = await axios.post(`guest-store`, {
                    fullname: this.shipping.fullname,
                    contact: this.shipping.contact,
                    email: this.shipping.email,
                    address: this.shipping.address,
                    city: this.shipping.city,
                    state: this.shipping.state,
                    zipcode: this.shipping.zipcode,
                    country: this.shipping.country,
                    property_type: this.shipping.property_type
                });

                this.status = response.data.status
                this.ok = response.data.message
                
               if (response.data.success) {
                    this.loading = false
                    localStorage.setItem('guest_id', response.data.data.id)                    
                    localStorage.setItem('isGuest', true)                    
                    this.$router.push('/shipping-cost')
                    this.$router.go(0)
                }
            }
            catch (e) {
                this.error = e.response.data.message
                this.errors = e.response.data.error
            }   
        }
    }
}
</script>