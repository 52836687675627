<template> 
    <div class="modal fade" id="orderModal" role="dialog" style="z-index:999999 !important">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button id="al" type="button" class="close modal-close" data-dismiss="modal" aria-label="close">&times;</button>
            </div>
            <div class="modal-body" style="max-height:800px; overflow:auto; overflow-x:hidden">
                <table class="table"> 
                    <tr>
                        <th>Product Name</th>
                        <th>Code</th>
                        <th>Image</th>
                    </tr>
                    <tr v-for="order in orderDetails" :key="order.id">
                        <td>{{ order.product.name }}</td>
                        <td>{{ order.product.code }}</td>
                        <td><img v-bind:src="order.product.image" v-bind:title="order.product.name" v-bind:alt="order.product.name" class="w-75"> </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'OrderDetails',
    props: ['orderDetails'],
};
</script>


