<template> 
    <div>
        <div class="row">
            <div class="merchant-bg-images d-flex">
                <div class="col-sm-12 text-center justify-content-center align-self-center d-flex text-shadow-headline">
                    Fastival
                </div>
            </div>
        </div>
        <div class="container mb-5 mt-5">  
            <div class="row">
                <div class="col-6 col-sm-4 col-md-3 col-lg-3" v-for="(item) in fastivals" :key="item.id">
                    <div class="fastival-page-thumbnail">
                        <router-link :to="{ path: '/fastival/'+item.slug}">
                            <img v-bind:src="item.image" v-bind:title="item.name" v-bind:alt="item.name" class="d-block w-100">
                            <h5 class="align-center">{{ item.name }}</h5>
                        </router-link>
                    </div> 
                </div>
            </div> 
        </div>
    </div>
</template>
<script>
import axios from '@/utils/axiosConfig';

export default {
    name: 'Fastival', 
    
    data(){
        return{
            error: '',
            fastivals: [],
        }
    },
    mounted() {
        this.getFastival();
    },
    methods:{   
        getFastival() {
            axios.get(`fastivals`).then((response) => {
                if(response.data.success){
                    this.fastivals = response.data.data;
                    // console.log(response.data.data)
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }
    },
};
</script>

