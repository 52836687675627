<template>
    <ul class="nav flex-column sidebar h-100">
        <li class="nav-item ">
            <a class="nav-link " href="javascript:void(0)"><i
                    class="fa fa-tachometer"></i>Dashboard</a>
        </li>
        <li class="nav-item ">
            <router-link to="/profile" class="nav-link"><i class="far fa-user"></i>My Accounts</router-link>
        </li>    
        <li class="nav-item ">
            <router-link to="/my-order" class="nav-link"><i class="fas fa-list"></i>My Orders</router-link>
        </li>
        <li class="nav-item ">
            <a class="nav-link " hr ef="javascript:void(0)"><i
                    class="far fa-heart"></i>Wishlist</a>
        </li>                   
        
        <li class="nav-item ">
            <a class="nav-link " href="https://www.facebook.com/Ethnetic FeedOnline" target="_blank"><i
                    class="fab fa-facebook-f"></i>Facebook Page</a>
        </li>
        <li class="nav-item ">
            <a class="nav-link " href="javascript:void(0)" @click="handleLogout"><i
                    class="fas fa-sign-out-alt"></i>Logout</a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Menu',
    methods: {
        handleLogout() {
            localStorage.removeItem('access_token')
            localStorage.removeItem('user_id')
            localStorage.removeItem('user')
            localStorage.clear();
            this.$router.push('/')
            this.$router.go(0)
        }
    }
}
</script>