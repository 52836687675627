<template>
    <div class="container-fluid" id="grad1">
        <div class="row justify-content-center mt-0">
            <div class="col-11 col-sm-10 col-md-10 col-lg-10 p-0 mt-3 mb-2">               
                <p>Dear {{ orders.customer?.full_name }},<br />
                We are thrilled to inform you that your order has been  successfully placed with EthnicMeal.com! 
                Your culinary journey is about to get  more exciting with the delicious and authentic flavors from around the world,  right at your doorstep.</p>
                <p><strong>Order Details:</strong></p>
                <ul type="disc">
                    <li><strong>Order Number:</strong> {{ orders.order_number }}</li>
                    <li><strong>Order Date:</strong> {{ orders.order_date }}</li>
                    <li><strong>Estimated Delivery Date:</strong> {{ orders.delivery_date }}</li>
                </ul>

                <p class="mt-4"><strong>Items Ordered:</strong></p>
                <table class="table" style="width:70%" v-if="orders.get_order_details!=''"> 
                    <tr>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Image</th>
                    </tr>
                    <tr v-for="order in orders.get_order_details" :key="order.id">
                        <td width="80%">{{ order.product.name }}</td>
                        <td width="5%">{{ order.quantity }}</td>
                        <td width="15%"><img v-bind:src="order.product.image" v-bind:title="order.product.name" v-bind:alt="order.product.name" class="w-100"> </td>
                    </tr>
                </table>

                <p><strong>Shipping Address:</strong><br />
                    {{ orders.shipping?.full_name }},<br />
                    {{ orders.shipping?.address }},<br />
                    {{ orders.shipping?.city }}, {{ orders.shipping?.states?.iso2 }}, {{ orders.shipping?.zipcode }}, {{ orders.shipping?.countries?.name }}<br />
                    {{ orders.shipping?.contact }}
                </p>

                <p><strong>Payment Summary:</strong></p>
                <ul type="disc">
                    <li><strong>Items Total:</strong> ${{ orders.order_payments?.shipping_charge }}</li>
                    <li><strong>Shipping &amp; Handling:</strong> ${{ orders.order_payments?.shipping_charge }}</li>
                    <li><strong>Tax:</strong> ${{ orders.order_payments?.estimate_tax }}</li>
                    <li><strong>Total       Amount Charged:</strong> ${{ orders.order_payments?.total_amount }}</li>
                </ul>

                <p class="mt-4">Your order will be shipped to the address provided, and a  notification will be sent to you once it's on its way. In the meantime, you can  track your order status or manage your order by visiting &quot;My Orders&quot;  on our website.</p>
                <p>Thank you for choosing EthnicMeal.com for your authentic  ethnic food experience. We're committed to providing you with the highest  quality products and a seamless shopping experience. If you have any questions  or need further assistance, please don't hesitate to reach out to our customer  service team.</p>
                <p>We hope you enjoy your upcoming ethnic culinary delights and  look forward to serving you again soon!</p>
                <p>Warm regards,</p>
                <p>The EthnicMeal.com Team</p>

            </div>  
        </div>
    </div>
</template>
<style scoped>
    ul {
        padding-left: 30px !important;
        list-style-type: disc !important;
    }
</style>
<script>
import axios from '@/utils/axiosConfig';

export default{
    name: "Addresses",
    props: ['itemTotal','shippingCharge','beforeTax','totalTax','couponPrice','grandTotal'] ,
    data() {
        return {
            user : '',
            orders : '',
            customers : [],
            shippings : []
        }
    },
    mounted() {
        if(this.$store.getters.isAuthenticated){
            this.getLastOrders('user','0')
        }
        else if(localStorage.getItem('isGuest')){
            this.getLastOrders('guest',localStorage.getItem('guest_id'))
        }
        else{
            this.$router.push({ path: '/login' })
        }
        this.guestClear()
    },
    methods:{
        async getLastOrders(type,guesetId) {
            axios.get(`last-order/` + type + '/' + guesetId).then((response) => {
                if(response.data.success){
                    this.orders = response.data.data;
                    this.customers = this.orders.customer
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        },
        async guestClear(){
            setTimeout(() => {                    
                localStorage.removeItem('guest_id')
                localStorage.removeItem('isGuest')
                // localStorage.removeItem('vuex')
                this.$store.dispatch('cart/clearCart');
                // this.$router.push({ path: '/' })
                this.$router.push({ path: '/' }).then(() => { this.$router.go(0) })
                // window.location.reload()
            }, 5000);
        }
    }
}
</script>