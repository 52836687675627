<template>
    <div class="page-wrapper" id="pageWrapper">
    <div class="page-body-wrapper">          
            <div class="page-body">
                <seller-banner :banners ='banners'/>
                <seller-information :merchantData='merchant' :businessData='business'/>
                <div id="sellerItems">
                    <seller-products :product='products'/>
                </div>
                <!-- <div class="container" id="sellerReview">
                    <div class="sellerContent">
                        <div class="row" v-for="(rev) in reviews" :key="rev.id">
                            <div class="col-sm-4">
                                <h5> {{ rev.review }}</h5>
                            </div>
                            <div class="col-sm-8">
                                <h5> {{ rev.rating }}</h5>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="container customer-review-message px-2 mt-5 bt" id="sellerReview">
                    <div class="row">   
                        <div class="col-sm-3">
                            <h5>Review</h5>
                        </div>
                        <div class="col-sm-9">
                            <div  class="review-message-item bn" v-for="(rev, ind) in reviews" :key="rev.id">
                                <div v-bind:class="ind > 0 ? 'bt' : ''">
                                    <div class="customer-review-summary">
                                        <div v-if="rev.rating > 0">
                                            <span v-for="index in rev.rating" :key="index">
                                                <i class="fa fa-star"></i>
                                            </span>
                                            
                                        </div>

                                        <span class="pl-2">by </span>
                                        <span class="customer-name">{{ rev.customer ? rev.customer.fullname : 'Admin' }}</span>
                                    </div>
                                    <div class="customer-message">
                                        <p>{{ rev.review}}</p>
                                    </div>
                                </div> 
                            </div>
                        </div>         
                        
                    </div>                   
                </div>

                <div class="container" id="aboutSeller">
                    <div class="sellerContent bt">
                        <div class="row" v-for="(cont, index) in contents" :key="cont.id">
                            <div class="col-sm-3">
                                <h5> {{ cont.menu.title }}</h5>
                            </div>
                            <div class="col-sm-9" v-bind:class="index > 0 ? 'bt' : ''">
                                <h4>{{ cont.title}}</h4>
                                <div class="details" v-html="cont.content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axiosConfig';
import SellerProducts from '@/components/homepage/SellerProducts.vue'
import SellerBanner from '@/components/homepage/SellerBanner.vue'
import SellerInformation from '../components/homepage/SellerInformation.vue';
import $ from 'jquery'

export default {
    name: 'Gallery', 
    components: {
        SellerProducts,
        SellerBanner,
        SellerInformation
    },
    data(){
        return{
            error: '',
            products: [],
            merchant: [],
            business: [],
            banners: [],
            contents: [],
            reviews: [],
        }
    },
    mounted() {
        this.getProduct(this.$route.params.slug);
    },
    methods:{   
        getProduct(slug) {
            axios.get('merchant-store/' + slug).then((response) => {
                if(response.data.success){
                    this.products = response.data.data.product.data;
                    this.business = response.data.data.merchant;
                    this.merchant = response.data.data.merchant.merchant;
                    this.banners = response.data.data.merchant.banners;
                    this.contents = response.data.data.merchant.contents;
                    this.reviews = response.data.data.merchant.review;

                    setTimeout(function() {
                        $('.banners').slick({
                            infinite: true,
                            speed: 300,
                            nextArrow: "#right-arrow .next-btn",
                            prevArrow: "#left-arrow .prev-btn",
                            slidesToShow: 1,
                            arrows:true,
                            mobileFirst: true,
                            slidesToScroll: 1,
                            autoplay:true,
                            responsive: [
                                {
                                breakpoint: 1600,
                                settings: {
                                    slidesToShow: 8,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    dots: true
                                }
                                },
                                {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 7,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    dots: true                                    
                                }
                                },
                                {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    dots: true
                                }
                                },
                                {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    dots: true
                                }
                                },

                                {
                                breakpoint: 300,
                                settings: {
                                    rows: 2,
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                    arrows: false,
                                    autoplay: false,
                                    touchMove: false,
                                    swipe: false
                                }
                                }
                            ]
                        });
                    }, 0); 
                }
                else{
                    this.error = 'URL Data Not Found';
                }            
            });
        }
    }
};
</script>