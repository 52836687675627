<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2 style="text-align: left; margin: 30px">{{ category.name }}</h2>
      </div>
    </div>
    <div class="row" v-if="products.length > 0">
      <div
        class="col-6 col-sm-4 col-md-3 col-lg-2 p-0 m-0"
        v-for="item in products"
        :key="item.id"
      >
        <div class="productCard">
          <div class="productImage">
            <router-link
              :to="{
                name: 'ProductDetails',
                params: { code: item.code, slug: item.slug },
              }"
            >
              <img
                v-bind:src="item.image"
                v-bind:title="item.name"
                v-bind:alt="item.name"
                class="productCard-img-top w-100"
              />
            </router-link>
          </div>
          <div class="productCard-body">
            <div class="productDetails">
              <!-- <h5 class="productCard-title">{{ item.name }}</h5> -->
              <h5 class="productCard-title">
                {{ item.name.substring(0, 50) + ".." }}
              </h5>
              <div class="ProductDiscount">
                <span class="productDiscountPrice"
                  >${{ item.discount_amount }}</span
                >
                <span class="discountPercent jss229"
                  >{{ item.discount }}{{ item.discount_type }} Off</span
                >
              </div>
              <p class="ProductPrice">${{ item.price }}</p>
            </div>
            <a
              href="javascript:void(0)"
              @click="
                $store.dispatch('addToCart', {
                  product_id: item.id,
                  seller_id: item.seller_id,
                })
              "
              class="btn btn-outline-primary d-grid"
              >ADD TO CART</a
            >
          </div>
        </div>
      </div>

    <div class="row mt-5 mb-3 justify-content-center align-center" id="pagination">
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item" v-for="(page, index) in pagination" :key="index">
                    <a class="page-link cursor" @click="getProduct(this.$route.params.slug, page.label)">
                        {{ page.label == "pagination.previous" ? "Previous" : (page.label == "pagination.next" ? "Next" : page.label) }}
                    </a>
                </li>
            </ul>
        </nav> 
    </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import axios from '@/utils/axiosConfig';

export default {
  name: "GalleryProducts",

  data() {
    return {
      error: "",
      products: [],
      category: [],
        pagination: [],
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0,
    };
  },
  mounted() {
    this.getProduct(this.$route.params.slug);
  },
  methods: {
    getProduct(slug, page) {
        let url = "products?category_name=" + slug;
        if(page != null){
            let nextPage = this.last_page == this.current_page ? this.last_page : this.current_page + 1;
            let previousPage = this.current_page == 1 ? this.current_page : this.current_page - 1;
            let pageNo = page == "pagination.previous" ? previousPage : (page == "pagination.next" ? nextPage : page)
            url = "products?category_name=" + slug + "&page=" + pageNo;
        }

      axios
        .get(url)
        .then((response) => {
          if (response.data.success) {
            this.products = response.data.data.data;
            this.category = response.data.additional;
            this.pagination = response.data.data.links;
            this.current_page = response.data.data.current_page;
            this.last_page = response.data.data.last_page;
            this.per_page = response.data.data.per_page;
            this.total = response.data.data.total;
          } else {
            this.error = "URL Data Not Found";
          }
        });
    },
  },
};
</script>

