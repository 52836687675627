<template> 
<div>    
    <div id="left-arrow" class="prev-center">
        <button class="prev-btn" id="al" aria-label="left arrow"><i class="fa-solid fa-angle-left"></i></button>
    </div>
    <div class="banners" v-if="banners.length > 0">
        <div v-for="(item) in banners" :key="item.id">
            <img v-bind:src="item.image" v-bind:title="item.heading" v-bind:alt="item.heading" class="d-block w-100">
        </div>
    </div>
    <div id="right-arrow" class="next-center">
        <button class="next-btn" id="al" aria-label="right arrow"><i class="fa-solid fa-angle-right"></i></button>
    </div>
    
</div>
</template>

<script>
export default {
    name: 'Banner', 
    props : ['banners'],
    data(){
        return{
            error: '',
        }
    }    
};
</script>

